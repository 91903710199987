import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  IconButton,
  MenuItem,
  Box,
  TextField,
  Button,
  autocompleteClasses,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/styles";

//export function TabStrip({ tabData, tabStripSelectedTab, setTabStripSelectedTab }) {
export default function TabStrip({
  tabData,
  setTabData,
  tabStripSelectedTab,
  setTabStripSelectedTab,
  handleAddTab,
  handleTabClick,
  handleTabCloseClick,
  tabsAvailable_SearchForm,
  tabsAvailable_EntitySPIandRank,
  tabsAvailable_Presets,
  APIData_compare,
  setAPIData_compare,
  setSPIScore_compare,
  setRank_compare,
  setSliceIsVisible_compare,
  setWeight_compare,
  setPresetNameIndex_compare,
  setSearchFormData_compare,
  setNumberOfEntities_compare,
}) {
  const theme = useTheme();
  const [compareTab, setCompareTab] = useState("");
  /*   const [hoveredTabIndex, setHoveredTabIndex] = useState(null); // State to track the hovered tab index
   */
  useEffect(() => {
    //console.log("tabData: ", tabData);
    //tabData.map((tab) => (
    //console.log("tabData: tab.tabId: ", tab.tabId)
    //))
    //console.log("tabData: tabStripSelectedTab: ", tabStripSelectedTab);
  }, [tabData, tabStripSelectedTab]);

  const handleCompareChange = (event) => {
    const selectedTabId = event.target.value;
    setCompareTab(selectedTabId);
    const selectedTab = tabData.find((tab) => tab.tabId === selectedTabId);
    if (selectedTab) {
      setSliceIsVisible_compare(selectedTab.sliceIsVisible);
      setWeight_compare(selectedTab.weight);
      setSPIScore_compare(selectedTab.SPIScore);
      setRank_compare(selectedTab.rank);

      setNumberOfEntities_compare(selectedTab.numberOfEntities);

      /////////////setPresetOptions(tabData[newValue].presetOptions);
      setPresetNameIndex_compare(selectedTab.presetNameIndex);
      setSearchFormData_compare(selectedTab.searchFormData);
      //reset the dropdown selection
      //setCompareTab("");

      setAPIData_compare(selectedTab.APIData);
      console.log("selectedTab.APIData: ", selectedTab.APIData);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        marginBottom: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start" /* "space-between" */,
          width: "100%",
          /* "@media (min-width: 900px)": {
            width: "auto",
          }, */
        }}
      >
        <Tabs
          value={tabStripSelectedTab}
          onChange={
            tabsAvailable_SearchForm &&
            tabsAvailable_EntitySPIandRank &&
            tabsAvailable_Presets
              ? handleTabClick
              : null
          } // Disable the close button if tabs are not available, ie is false
          style={{ paddingBottom: "00px", paddingLeft: "10px" }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabData.map((tab, index) => (
            <Tab
              key={index}
              label={
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  {"Search " + String(tab.tabId)}
                  {index !== 0 ? (
                    <IconButton
                      size="small"
                      onClick={(event) => handleTabCloseClick(event, index)}
                      sx={{
                        color:
                          tabStripSelectedTab === index ? "white" : "inherit", // Change color based on hover state and selected tab
                        padding: "4px", // Adjust the padding to make the button smaller
                        "& .MuiSvgIcon-root": {
                          fontSize: "1rem", // Adjust the font size of the icon
                        },
                        "&:hover": {
                          backgroundColor: "grey",
                          color: "white",
                        },
                      }}
                      disabled={
                        !(
                          tabsAvailable_SearchForm &&
                          tabsAvailable_EntitySPIandRank &&
                          tabsAvailable_Presets
                        )
                      } // Disable the close button if tabs are not available, ie is false
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <div style={{ height: 30 }}></div> // Placeholder for consistent height on first tab (permanent tab)
                  )}
                </div>
              }
              sx={{
                minHeight: "30px", // Adjust the height of the tabs
                padding: "8px 12px", // Adjust the padding of the tabs
                fontSize: "0.675rem", // Adjust the font size of the tabs
                backgroundColor:
                  tabStripSelectedTab === index
                    ? theme.palette.skyBlue.main
                    : "lightgrey",
                color: tabStripSelectedTab === index ? "white" : "black",
                borderRadius: "5px 5px 0 0",
                marginRight: "2px",
                "&.Mui-selected": {
                  backgroundColor: theme.palette.skyBlue.main,
                  color: "white",
                  fontWeight: "bold",
                },
                "&:hover": {
                  backgroundColor:
                    tabStripSelectedTab === index
                      ? theme.palette.darkBlue.main
                      : "darkgrey",
                  color: "white",
                },
                pointerEvents:
                  tabsAvailable_SearchForm &&
                  tabsAvailable_EntitySPIandRank &&
                  tabsAvailable_Presets
                    ? "auto"
                    : "none", // Disable pointer events if tabsAvailable_SearchForm is false
                cursor:
                  tabsAvailable_SearchForm &&
                  tabsAvailable_EntitySPIandRank &&
                  tabsAvailable_Presets
                    ? "pointer"
                    : "default", // Change cursor to default if tabsAvailable_SearchForm is false
                opacity:
                  tabsAvailable_SearchForm &&
                  tabsAvailable_EntitySPIandRank &&
                  tabsAvailable_Presets
                    ? 1
                    : 0.6, // Reduce opacity if tabsAvailable_SearchForm is false
              }}
              /*               onMouseEnter={() => setHoveredTabIndex(index)} // Set hover state to the current tab index
              onMouseLeave={() => setHoveredTabIndex(null)} // Reset hover state
 */
            />
          ))}
        </Tabs>
        <IconButton
          size="small"
          onClick={handleAddTab}
          style={{
            marginBottom: "0px",
            marginRight: "14px",
            marginLeft: "10px",
          }}
          sx={{
            "&:hover": {
              backgroundColor: "grey",
              color: "white",
            },
          }}
          disabled={
            !(
              tabsAvailable_SearchForm &&
              tabsAvailable_EntitySPIandRank &&
              tabsAvailable_Presets
            )
          } // Disable the add tab button if tabs are not available, ie is false
        >
          <AddIcon fontSize="medium" />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          flexDirection: "column", // Always stack vertically
          "@media (min-width: 768px)": {
            flexDirection: "row", // Stack horizontally for screens 768px and above
          },
        }}
      >
        <TextField
          select
          label="Compare to"
          value={compareTab}
          onChange={handleCompareChange}
          disabled={
            !(
              tabsAvailable_SearchForm &&
              tabsAvailable_EntitySPIandRank &&
              tabsAvailable_Presets
            )
          }
          sx={{
            minWidth: 130,
            height: "40px", // Adjust the height to match the button
            "& .MuiInputBase-root": {
              height: "100%", // Ensure the input base takes the full height
              padding: "0 0px", // 14pxAdjust padding to match the button
              display: "flex",
              alignItems: "center",
            },
            "& .MuiInputLabel-root": {
              top: "50%", // Vertically center the label
              transform: "translateY(-50%)", // Adjust the transform to center the label
              lineHeight: "normal", // Reset line-height to normal
              left: "10px", // Move the label to the right
            },
            "& .MuiInputLabel-shrink": {
              top: "-8px", // Adjust the top position when the label is shrunk
              transform: "translateY(0)", // Reset the transform when the label is shrunk
              fontSize: "0.8rem", // Adjust the font size when the label is shrunk
              left: "12px", // Move the label to the right by 2px when shrunk
            },
            "& .MuiSelect-select": {
              display: "flex",
              alignItems: "center",
              height: "100%", // Ensure the select takes the full height
              padding: "0 14px", // Adjust padding to match the button
            },
          }}
        >
          {tabData.map((tab) => (
            <MenuItem
              key={tab.tabId}
              value={tab.tabId}
              disabled={tab.tabId === tabData[tabStripSelectedTab].tabId} // Disable if tabId matches the selected tab's tabId
            >
              {"Search " + String(tab.tabId)}
            </MenuItem>
          ))}
        </TextField>
        {APIData_compare !== "" && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setAPIData_compare("");
              setCompareTab("");
            }}
            sx={{
              // Adjust margin based on screen size
              ml: { xs: 0, md: 2 },
              mt: { xs: 0, md: 0 },
              backgroundColor: theme.palette.magenta.main,
              "&:hover": {
                backgroundColor: theme.palette.purple.main,
              },
            }}
          >
            Close Compare
          </Button>
        )}
      </Box>
    </Box>
  );
}
