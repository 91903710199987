//import * as React from "react";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  styled,
  createTheme,
  /* ThemeProvider, */
  useTheme,
} from "@mui/material/styles";
//import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { MenuItems } from "./MenuItems";
import EntitySPIandRank from "./EntitySPIandRank";
import Presets from "./Presets";
import "./styles.css";

import SearchForm from "./Components/SearchForm";

//import Search from "./Search";

//import CiambellaChart from "./CiambellaChart";
import CiambellaChart_2 from "./CiambellaChart_2";

import Legend from "./Legend";
import THRIVEGrid from "./THRIVEGrid";
import DisplayClassification from "./DisplayClassification";
import axios from "axios";
import { serverDomain } from "./config";

import SnippetPopUp from "./SnippetPopUp";
import ProfileEditorPopUp from "./ProfileEditorPopUp";

import TabStrip from "./Components/TabStrip";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://strive2thrive.earth/">
        Strive2Thrive.Earth
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

//const mdTheme = createTheme();
/* const mdTheme = createTheme({
  palette: {
    primary: {
      main: '#00f',
    },
    secondary: {
      main: '#0f0',
    },
  },
}); */

function DashboardContent() {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  // State to track the level chosen from the SearchForm
  const [levelForAppBarColour, setLevelForAppBarColour] = useState(2);

  // Array of colors for different levels
  const levelColors = {
    2: "#be91d4",
    3: "#f1818d",
    4: "#65c6bb",
    5: "#6cb9f1",
    6: "#26c281",
    7: "#4183d7",
    // Add more levels and colors as needed
  };

  // UseEffect to update the AppBar color when levelForAppBarColour changes
  useEffect(() => {
    const appBarColor = levelColors[levelForAppBarColour] || "defaultColor";
    document.querySelector(".MuiAppBar-root").style.backgroundColor =
      appBarColor;
    console.log(
      `Dashboard: levelForAppBarColour: level and Color ${levelForAppBarColour} : ${appBarColor}`
    );
  }, [levelForAppBarColour]);

  //state and click handler for SnippetPopUp...
  const [isPopupOpen, setPopupOpen] = React.useState(false);
  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  //state and click handler for ProfileEditorPopUp...
  const [profileOpen, setProfileOpen] = React.useState(false);

  //initialize the APIData state variable...
  const [APIData, setAPIData] = useState("");

  //initialize the APIData_compare state variable...
  const [APIData_compare, setAPIData_compare] = useState("");

  const MAXIMUM_NUMBER_OF_SLICES = 5000;

  //initialize the sliceIsVisible_compare state variable...
  const [sliceIsVisible_compare, setSliceIsVisible_compare] = useState(
    Array(MAXIMUM_NUMBER_OF_SLICES).fill(true)
  );

  //initialize the weighte_compare state variable...
  const [weight_compare, setWeight_compare] = useState(
    Array(MAXIMUM_NUMBER_OF_SLICES).fill(1)
  );

  //initialize the SPIScore_compare state variable...
  const [SPIScore_compare, setSPIScore_compare] = useState(null);

  //initialize the rank_compare state variable...
  const [rank_compare, setRank_compare] = useState(null);

  //initialize the numberOfEntities_compare state variable. This is used for displaying rank x of y, where y is the total NumberOfEntities in seaqrch result...
  const [numberOfEntities_compare, setNumberOfEntities_compare] = useState(0);

  //initialize the presetNameIndex_compare state...
  const [presetNameIndex_compare, setPresetNameIndex_compare] = useState(0);

  //initialize searchFormDate state variable. Holds the current search form selections...
  const [searchFormData_compare, setSearchFormData_compare] = useState({});

  //initialize the sliceIsVisible boolean array state variable with 200 true elements...
  const [sliceIsVisible, setSliceIsVisible] = useState(
    Array(MAXIMUM_NUMBER_OF_SLICES).fill(true)
  );

  //initialize the weight array state variable with 200 elements of value=1...
  const [weight, setWeight] = useState(Array(MAXIMUM_NUMBER_OF_SLICES).fill(1));

  //I put selectAllSlices state variable here in the parent qnd passed down to Grid and Ciambella Chart
  //And added to the dependency array of the useEffect hook in CiambellaChart.js to see if it would update
  //the Ciambella Chart when the selectAllSlices checkbox is toggled to prevent the bug where the inner text
  //of the Ciambella Chart does not update when the selectAllSlices checkbox is toggled. But it didn't fix it.
  //create state for selectAllSlices checkbox...
  const [selectAllSlices, setSelectAllSlices] = useState(true);
  const [selectAllSlices_compare, setSelectAllSlices_compare] = useState(true); //IS THIS USED???????

  function resetWeights() {
    setWeight(Array(MAXIMUM_NUMBER_OF_SLICES).fill(1));
  }

  function resetWeights_compare() {
    setWeight_compare(Array(MAXIMUM_NUMBER_OF_SLICES).fill(1));
  }

  function resetSlices() {
    setSliceIsVisible(Array(MAXIMUM_NUMBER_OF_SLICES).fill(true));
  }

  // Initialize tabsAvailable_SearchForm state variable to true. This controls the availability of tabs in the SearchForm component...
  const [tabsAvailable_SearchForm, setTabsAvailable_SearchForm] =
    useState(true);

  // Initialize tabsAvailable_EntitySPIandRank state variable to true. This controls the availability of tabs in the EntitySPIandRank component...
  const [tabsAvailable_EntitySPIandRank, setTabsAvailable_EntitySPIandRank] =
    useState(true);

  // Initialize tabsAvailable_Presets state variable to true. This controls the availability of tabs in the Presets component...
  const [tabsAvailable_Presets, setTabsAvailable_Presets] = useState(true);

  //initialize searchFormDate state variable. Holds the current search form selections...
  const [searchFormData, setSearchFormData] = useState({});

  //initialize the numberOfEntities state variable. This is used for displaying rank x of y, where y is the total NumberOfEntities in seaqrch result...
  const [numberOfEntities, setNumberOfEntities] = useState(0);

  //initialize the SPIScore state variable...
  const [SPIScore, setSPIScore] = useState(null);

  //initialize the rank state variable...
  const [rank, setRank] = useState(null);

  //initialize the level, class, weightEngine, formulaEngine, and entityID state variable for the tab data object...
  const [levelId, setLevelId] = useState("");
  const [classId, setClassId] = useState(0);
  const [wfId, setWfId] = useState(0);
  const [feId, setfeId] = useState(0);
  const [entityId, setEntityId] = useState({ id: "", name: "" });

  //initialize the presetOtpions array state variable...
  //const [presetOptions, setPresetOptions] = useState([]);

  //initialize the snippetDataObject state variable.
  //This is used to store the chart data from ccjsv6.js that will be
  //passed to the SnippetPopUp component and written out as javascript text...
  const [snippetDataObject, setSnippetDataObject] = useState({});
  const [snippetDataObject_compare, setSnippetDataObject_compare] = useState(
    {}
  );

  //log it's value when it changes, for testing...
  useEffect(() => {
    console.log("snippetDataObject changed.");
    console.log("*****snippetDataObject*********");
    console.log("snippetDataObject", snippetDataObject);
    console.log("*****snippetDataObject*********");
    console.log("snippetDataObject", JSON.stringify(snippetDataObject));
  }, [snippetDataObject]);

  // tab data array and selected tab index...
  const [tabData, setTabData] = useState([
    {
      tabId: 1,
      APIData: "",
      sliceIsVisible: Array(MAXIMUM_NUMBER_OF_SLICES).fill(true),
      weight: Array(MAXIMUM_NUMBER_OF_SLICES).fill(1),
      searchFormData: {
        feId: "",
        wfId: "",
        classId: "",
        levelId: 2,
        entityId: { id: "", name: "" },
        //dateTimeStartId: new Date().toISOString(), // Default to current date-time
        //dateTimeEndId: new Date().toISOString(), // Default to current date-time
        dateTimeStartId: new Date("2019-01-01T00:00:00.000Z").toISOString(), // Set to 01/01/2019
        dateTimeEndId: new Date("2020-01-01T00:00:00.000Z").toISOString(), // Set to 01/01/2020
      },
      rank: null,
      numberOfEntities: 0,
      SPIScore: null,
      entities: [],
      presetNameIndex: 0,
    },
  ]);

  const [tabStripSelectedTab, setTabStripSelectedTab] = useState(0);

  // useEffect to store searchFormData in tabData[] when APIData changes.
  // i.e. after asearch is perfomed and APIData is set...

  useEffect(() => {
    if (APIData) {
      console.log("APIData has changed:", APIData);
      // store in the current tab tabData object...
      tabData[tabStripSelectedTab].searchFormData = searchFormData;
      console.log(
        "GETFD searchFormData stored in tabData[",
        tabStripSelectedTab,
        "]: ",
        tabData[tabStripSelectedTab].searchFormData
      );
      console.log("GETFD searchFormData state ", searchFormData);
    }
  }, [APIData, tabStripSelectedTab, tabData, searchFormData]);

  //The maximum number of tabs that can be created by the user...
  let MAXIMUM_TABS = 64;

  function handleAddTab() {
    if (tabData.length < MAXIMUM_TABS) {
      //Set the new tab ID to be one more than the tabId of the last tab....
      const newTabId = tabData[tabData.length - 1].tabId + 1;
      const newTab = {
        tabId: newTabId,
        APIData: "",
        sliceIsVisible: Array(MAXIMUM_NUMBER_OF_SLICES).fill(true),
        weight: Array(MAXIMUM_NUMBER_OF_SLICES).fill(1),
        searchFormData: {
          feId: "",
          wfId: "",
          classId: "",
          levelId: 2,
          entityId: { id: "", name: "" },
          //dateTimeStartId: new Date().toISOString(), // Default to current date-time
          //dateTimeEndId: new Date().toISOString(), // Default to current date-time
          dateTimeStartId: new Date("2019-01-01T00:00:00.000Z").toISOString(), // Set to 01/01/2019
          dateTimeEndId: new Date("2020-01-01T00:00:00.000Z").toISOString(), // Set to 01/01/2020
        },
        rank: null,
        numberOfEntities: 0,
        SPIScore: null,
        entities: [],
        presetNameIndex: 0,
      };
      setTabData((prevTabs) => [...prevTabs, newTab]);
      console.log("Create new tab tabId: " + newTabId);

      //set tabStripSelectedTab to the last elements index, so new tab is selected...
      setTabStripSelectedTab(tabData.length);
    }
    // now in useEffect hook: console.log("tabData: ", tabData);
    //Reset APIData to empty string when new tab is created so no chart, grid or legend displays...
    setAPIData("");
    //Set SPIScore to 0 when new tab is created so no SPI Score displays...
    setSPIScore(null);
    //Set Rank to 0 when new tab is created so no Rank displays...
    setRank(null);
  }

  const handleTabClick = (event, newValue) => {
    setTabStripSelectedTab(newValue);
    console.log(
      "Tab clicked: Search " + tabData[newValue].tabId + ", index: " + newValue
    );
    console.log("tabData: ", tabData);
    setAPIData(tabData[newValue].APIData);

    //retrieve the searchformdata...
    setSearchFormData(tabData[newValue].searchFormData);
    console.log("NEWTABSFD", tabData[newValue].searchFormData);
    setSliceIsVisible(tabData[newValue].sliceIsVisible);
    setWeight(tabData[newValue].weight);
    setSPIScore(tabData[newValue].SPIScore);
    setRank(tabData[newValue].rank);
    //Doesn't need to be set from tabData when changing tabs, as it gets set when the
    //search form re-renders and getEntities is rerun. Also it gets set when a search is done.
    //The state for  setNumberOfEntities_compare is set in the TabStrip component when the
    //'compare to' button is clicked...
    //setNumberOfEntities(tabData[newValue].numberOfEntities);

    //setPresetOptions(tabData[newValue].presetOptions); //not needed as presetOptions is set in Presets component
    setPresetNameIndex(tabData[newValue].presetNameIndex);
    /* add setter for states
          levelId: "",
      classId: 0,
      wfId: 0,
      feId: 0,
      entityId: { id: "", name: "" }*/

    /*     setLevelId(tabData[newValue].levelId);
    setClassId(tabData[newValue].classId);
    setWfId(tabData[newValue].wfId);
    setfeId(tabData[newValue].feId);
    setEntityId(tabData[newValue].entityId); */

    console.log("SPIScore and rank retrieved from tab: ", SPIScore, rank);
    console.log("TABSFD", "", searchFormData);
  };

  function handleTabCloseClick(event, index) {
    event.stopPropagation();
    console.log("Close tab " + tabData[index].tabId + ", index: " + index);

    setTabData((prevTabs) => prevTabs.filter((_, i) => i !== index));
    // Adjust the selected tab index if necessary
    if (tabStripSelectedTab >= index) {
      setTabStripSelectedTab((prevValue) =>
        prevValue > 0 ? prevValue - 1 : 0
      );
    }

    // Determine the new selected tab index before updating the state
    const newValue = tabStripSelectedTab > 0 ? tabStripSelectedTab - 1 : 0;

    //load the APIData, sliceIsVisible, weight, SPIScore and rank from the selected tab...
    if (tabData[newValue]) {
      console.log("Close tab: newValue: ", newValue);
      setAPIData(tabData[newValue].APIData);
      setSliceIsVisible(tabData[newValue].sliceIsVisible);
      setWeight(tabData[newValue].weight);
      setSPIScore(tabData[newValue].SPIScore);
      setRank(tabData[newValue].rank);
      //setNumberOfEntities(tabData[newValue].numberOfEntities);
      //setPresetOptions(tabData[newValue].presetOptions);
      setPresetNameIndex(tabData[newValue].presetNameIndex);
      setSearchFormData(tabData[newValue].searchFormData);
    }
    // now in useEffect hook: console.log("tabData: ", tabData);
    console.log("CLOSETAB: searchFormData: ", searchFormData);
  }

  //initialize the presetNameIndex state...
  const [presetNameIndex, setPresetNameIndex] = useState(0);

  //Put APIData into tabData at current tab index when APIData changes...
  //Put presetOptions into tabData at current tab index when APIData changes...
  useEffect(() => {
    setTabData((prevTabs) => {
      const newTabs = [...prevTabs];
      if (newTabs[tabStripSelectedTab]) {
        newTabs[tabStripSelectedTab].APIData = APIData;
        //newTabs[tabStripSelectedTab].presetOptions = presetOptions;
        newTabs[tabStripSelectedTab].presetNameIndex = presetNameIndex;
      }
      return newTabs;
    });

    //FOR TEST OF TWO COLUMN DISPLAY - put APIData into APIData_compare when APIData changes...
    //setAPIData_compare(APIData);
  }, [APIData, presetNameIndex, tabStripSelectedTab]);

  //Put sliceIsVisible aray into tabData at current tab index when sliceIsVisible array changes...
  useEffect(() => {
    setTabData((prevTabs) => {
      const newTabs = [...prevTabs];
      if (newTabs[tabStripSelectedTab]) {
        newTabs[tabStripSelectedTab].sliceIsVisible = sliceIsVisible;
      }
      return newTabs;
    });
  }, [sliceIsVisible, tabStripSelectedTab]);

  //Put weight array into tabData at current tab index when weight array changes...
  useEffect(() => {
    setTabData((prevTabs) => {
      const newTabs = [...prevTabs];
      if (newTabs[tabStripSelectedTab]) {
        newTabs[tabStripSelectedTab].weight = weight;
      }
      return newTabs;
    });
  }, [weight, tabStripSelectedTab]);

  //Put SPIScore into tabData at current tab index when SPIScore changes...
  useEffect(() => {
    setTabData((prevTabs) => {
      const newTabs = [...prevTabs];
      if (newTabs[tabStripSelectedTab]) {
        newTabs[tabStripSelectedTab].SPIScore = SPIScore;
      }
      return newTabs;
    });
  }, [SPIScore, tabStripSelectedTab]);

  //Put rank into tabData at current tab index when rank changes...
  useEffect(() => {
    setTabData((prevTabs) => {
      const newTabs = [...prevTabs];
      if (newTabs[tabStripSelectedTab]) {
        newTabs[tabStripSelectedTab].rank = rank;
      }
      return newTabs;
    });
  }, [rank, tabStripSelectedTab]);

  //Put numberOfEntities into tabData at current tab index when numberOfEntities changes...
  useEffect(() => {
    setTabData((prevTabs) => {
      const newTabs = [...prevTabs];
      if (newTabs[tabStripSelectedTab]) {
        newTabs[tabStripSelectedTab].numberOfEntities = numberOfEntities;
      }
      return newTabs;
    });
  }, [numberOfEntities, tabStripSelectedTab]);

  /* $(function () {
    console.log("load event");
    //LegendBox(); //it runs everytime the menu icon is clicked! Why
  }); */

  // Mediaquery breakpoints
  //const theme = useTheme();
  //const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  //const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  //Was below, themprovide is now in index.js and uses custom ThriveBrandTheme ... {/* <ThemeProvider theme={mdTheme}> */}

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1, fontSize: "1rem" }} // Adjust font size for Typography
          >
            Dashboard
          </Typography>
          {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
          <Box sx={{ fontSize: "0.875rem" }}>
            {" "}
            {/* Adjust font size for plain text */}
            THRIVE Platform - V3.1
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <List>
          <MenuItems setProfileOpen={setProfileOpen} />
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                <ProfileEditorPopUp
                  profileOpen={profileOpen}
                  setProfileOpen={setProfileOpen}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TabStrip
                      tabData={tabData}
                      setTabData={setTabData}
                      tabStripSelectedTab={tabStripSelectedTab}
                      setTabStripSelectedTab={setTabStripSelectedTab}
                      handleAddTab={handleAddTab}
                      handleTabClick={handleTabClick}
                      handleTabCloseClick={handleTabCloseClick}
                      tabsAvailable_SearchForm={tabsAvailable_SearchForm}
                      tabsAvailable_EntitySPIandRank={
                        tabsAvailable_EntitySPIandRank
                      }
                      tabsAvailable_Presets={tabsAvailable_Presets}
                      APIData_compare={APIData_compare}
                      setAPIData_compare={setAPIData_compare}
                      setSPIScore_compare={setSPIScore_compare}
                      setRank_compare={setRank_compare}
                      setSliceIsVisible_compare={setSliceIsVisible_compare}
                      setWeight_compare={setWeight_compare}
                      setPresetNameIndex_compare={setPresetNameIndex_compare}
                      setSearchFormData_compare={setSearchFormData_compare}
                      setNumberOfEntities_compare={setNumberOfEntities_compare}
                    />
                  </Grid>
                  <SearchForm
                    setAPIData={setAPIData}
                    resetSlices={resetSlices}
                    resetWeights={resetWeights}
                    setSearchFormData={setSearchFormData}
                    searchFormData={searchFormData}
                    setNumberOfEntities={setNumberOfEntities}
                    setSPIScore={setSPIScore}
                    setRank={setRank}
                    setPresetNameIndex={setPresetNameIndex}
                    tabData={tabData}
                    setTabsAvailable_SearchForm={setTabsAvailable_SearchForm}
                    tabsAvailable_SearchForm={tabsAvailable_SearchForm}
                    setLevelForAppBarColour={setLevelForAppBarColour}
                    tabsAvailable_EntitySPIandRank={
                      tabsAvailable_EntitySPIandRank
                    }
                  />
                </Box>
              </Paper>
            </Grid>

            {APIData_compare === "" && APIData !== "" && (
              <>
                <Grid item xs={12}>
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <EntitySPIandRank
                      APIData={APIData}
                      sliceIsVisible={sliceIsVisible}
                      weight={weight}
                      searchFormData={searchFormData}
                      numberOfEntities={numberOfEntities}
                      setSPIScore={setSPIScore}
                      SPIScore={SPIScore}
                      setRank={setRank}
                      rank={rank}
                      setTabsAvailable_EntitySPIandRank={
                        setTabsAvailable_EntitySPIandRank
                      }
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={7}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      maxHeight: "660px",
                      overflow: "hidden",
                    }}
                  >
                    {/*Snippet PopUp*/}
                    <div>
                      {APIData !== "" && (
                        <SnippetPopUp
                          open={isPopupOpen}
                          setOpen={togglePopup}
                          buttonText="Get HTML snippet"
                          htmlCode={snippetDataObject}
                          tabsAvailable_SearchForm={tabsAvailable_SearchForm}
                          tabsAvailable_EntitySPIandRank={
                            tabsAvailable_EntitySPIandRank
                          }
                          tabsAvailable_Presets={tabsAvailable_Presets}
                          /* SPIScore={null} */
                        />
                      )}
                    </div>

                    <CiambellaChart_2
                      setSnippetDataObject={setSnippetDataObject}
                      APIData={APIData}
                      sliceIsVisible={sliceIsVisible}
                      weight={weight}
                      selectAllSlices={selectAllSlices}
                      SPIScore={SPIScore}
                      searchFormData={searchFormData}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: "660px",
                      overflowY: "auto",
                    }}
                  >
                    {APIData !== "" && APIData.includes("allocation") && (
                      <Presets
                        APIData={APIData}
                        searchFormData={searchFormData}
                        setSliceIsVisible={setSliceIsVisible}
                        presetNameIndex={presetNameIndex}
                        setPresetNameIndex={setPresetNameIndex}
                        resetWeights={resetWeights}
                        setTabsAvailable_Presets={setTabsAvailable_Presets}
                      />
                    )}
                    {/* Classification */}
                    <Typography id="legendTitle" component="p" variant="h5">
                      <DisplayClassification
                        APIData={APIData}
                        sliceIsVisible={sliceIsVisible}
                        weight={weight}
                      />
                    </Typography>
                    <Legend
                      APIData={APIData}
                      sliceIsVisible={sliceIsVisible}
                      setSliceIsVisible={setSliceIsVisible}
                      weight={weight}
                      searchFormData={searchFormData}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <THRIVEGrid
                      APIData={APIData}
                      sliceIsVisible={sliceIsVisible}
                      setSliceIsVisible={setSliceIsVisible}
                      weight={weight}
                      setWeight={setWeight}
                      selectAllSlices={selectAllSlices}
                      setSelectAllSlices={setSelectAllSlices}
                    />
                  </Paper>
                </Grid>
              </>
            )}
            {APIData_compare !== "" && (
              <>
                {/* ----- First column of Comparison - From Search Form ----- */}
                <Grid item xs={12} md={6}>
                  <Paper
                    sx={{
                      mb: 2,
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center", // Center the text horizontally
                      justifyContent: "center", // Center the text vertically
                      height: "50px", // Adjust the height to fit the text label
                      backgroundColor: theme.palette.skyBlue.main, // Set background to skyBlue color
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "white", // Set text color to white
                        fontWeight: "bold", // Make text bold
                      }}
                    >
                      Current search
                    </Typography>
                  </Paper>
                  <Paper
                    sx={{
                      mb: 2,
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      [theme.breakpoints.down("md")]: {
                        flexGrow: 1, // Apply flexGrow only on medium screens and below
                      },
                      [theme.breakpoints.up("md")]: {
                        height: "250px", // Ensure height does not change on screens 900px and above
                      },
                    }}
                  >
                    <EntitySPIandRank
                      APIData={APIData}
                      sliceIsVisible={sliceIsVisible}
                      weight={weight}
                      searchFormData={searchFormData}
                      numberOfEntities={numberOfEntities}
                      setSPIScore={setSPIScore}
                      SPIScore={SPIScore}
                      setRank={setRank}
                      rank={rank}
                      setTabsAvailable_EntitySPIandRank={
                        setTabsAvailable_EntitySPIandRank
                      }
                    />
                  </Paper>
                  <Paper
                    sx={{
                      mb: 2,
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      [theme.breakpoints.down("md")]: {
                        flexGrow: 1, // Apply flexGrow only on medium screens and below
                        maxHeight: "820px", // Ensure height does not change on screens medium and below
                      },
                      [theme.breakpoints.up("md")]: {
                        height: "250px", // Ensure height does not change on screens 900px and above
                        minHeight: "600px",
                      },
                      /* minHeight: "600px", */
                    }}
                  >
                    {/*Snippet PopUp*/}
                    <div>
                      {APIData !== "" && (
                        <SnippetPopUp
                          open={isPopupOpen}
                          setOpen={togglePopup}
                          buttonText="Get HTML snippet"
                          htmlCode={snippetDataObject}
                          tabsAvailable_SearchForm={tabsAvailable_SearchForm}
                          tabsAvailable_EntitySPIandRank={
                            tabsAvailable_EntitySPIandRank
                          }
                          tabsAvailable_Presets={tabsAvailable_Presets}
                          /* SPIScore={null} */
                        />
                      )}
                    </div>

                    <CiambellaChart_2
                      setSnippetDataObject={setSnippetDataObject}
                      APIData={APIData}
                      sliceIsVisible={sliceIsVisible}
                      weight={weight}
                      selectAllSlices={selectAllSlices}
                      SPIScore={SPIScore}
                      searchFormData={searchFormData}
                    />
                  </Paper>
                  <Paper
                    sx={{
                      mb: 2,
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: "500px",
                      overflowY: "auto",
                    }}
                  >
                    {APIData !== "" && APIData.includes("allocation") && (
                      <Presets
                        APIData={APIData}
                        searchFormData={searchFormData}
                        setSliceIsVisible={setSliceIsVisible}
                        presetNameIndex={presetNameIndex}
                        setPresetNameIndex={setPresetNameIndex}
                        resetWeights={resetWeights}
                        setTabsAvailable_Presets={setTabsAvailable_Presets}
                      />
                    )}
                    {/* Classification */}
                    <Typography id="legendTitle" component="p" variant="h5">
                      <DisplayClassification
                        APIData={APIData}
                        sliceIsVisible={sliceIsVisible}
                        weight={weight}
                      />
                    </Typography>
                    <Legend
                      APIData={APIData}
                      sliceIsVisible={sliceIsVisible}
                      setSliceIsVisible={setSliceIsVisible}
                      weight={weight}
                      searchFormData={searchFormData}
                    />
                  </Paper>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: "500px",
                      overflowY: "auto",
                    }}
                  >
                    <Box sx={{ mt: 2 }}>
                      <THRIVEGrid
                        APIData={APIData}
                        sliceIsVisible={sliceIsVisible}
                        setSliceIsVisible={setSliceIsVisible}
                        weight={weight}
                        setWeight={setWeight}
                        selectAllSlices={selectAllSlices}
                        setSelectAllSlices={setSelectAllSlices}
                      />
                    </Box>
                  </Paper>
                </Grid>
                {/* ----- Second column of Comparison - From 'Compare to' dropdown ----- */}
                <Grid item xs={12} md={6}>
                  <Paper
                    sx={{
                      mb: 2,
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center", // Center the text horizontally
                      justifyContent: "center", // Center the text vertically
                      height: "50px", // Adjust the height to fit the text label
                      backgroundColor: theme.palette.magenta.main, // Set background to skyBlue color
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        color: "white", // Set text color to white
                        fontWeight: "bold", // Make text bold
                      }}
                    >
                      Compare to
                    </Typography>
                  </Paper>
                  <Paper
                    sx={{
                      mb: 2,
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      [theme.breakpoints.down("md")]: {
                        flexGrow: 1, // Apply flexGrow only on medium screens and below
                      },
                      [theme.breakpoints.up("md")]: {
                        height: "250px", // Ensure height does not change on screens 900px and above
                      },
                    }}
                  >
                    <EntitySPIandRank
                      APIData={APIData_compare}
                      sliceIsVisible={sliceIsVisible_compare}
                      weight={weight_compare}
                      searchFormData={searchFormData_compare}
                      numberOfEntities={numberOfEntities_compare}
                      setSPIScore={setSPIScore_compare}
                      SPIScore={SPIScore_compare}
                      setRank={setRank_compare}
                      rank={rank_compare}
                      setTabsAvailable_EntitySPIandRank={
                        setTabsAvailable_EntitySPIandRank
                      }
                    />
                  </Paper>
                  <Paper
                    sx={{
                      mb: 2,
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      [theme.breakpoints.down("md")]: {
                        flexGrow: 1, // Apply flexGrow only on medium screens and below
                        maxHeight: "820px", // Ensure height does not change on screens medium and below
                      },
                      [theme.breakpoints.up("md")]: {
                        height: "250px", // Ensure height does not change on screens 900px and above
                        minHeight: "600px",
                      },
                      /* minHeight: "600px", */
                    }}
                  >
                    {/*Snippet PopUp*/}
                    <div>
                      {APIData !== "" && (
                        <SnippetPopUp
                          open={isPopupOpen}
                          setOpen={togglePopup}
                          buttonText="Get HTML snippet"
                          htmlCode={snippetDataObject_compare}
                          tabsAvailable_SearchForm={tabsAvailable_SearchForm}
                          tabsAvailable_EntitySPIandRank={
                            tabsAvailable_EntitySPIandRank
                          }
                          tabsAvailable_Presets={tabsAvailable_Presets}
                          /* SPIScore={null} */
                        />
                      )}
                    </div>

                    <CiambellaChart_2
                      setSnippetDataObject={setSnippetDataObject_compare}
                      APIData={APIData_compare}
                      sliceIsVisible={sliceIsVisible_compare}
                      weight={weight_compare}
                      selectAllSlices={selectAllSlices_compare}
                      SPIScore={SPIScore_compare}
                      searchFormData={searchFormData_compare}
                    />
                  </Paper>
                  <Paper
                    sx={{
                      mb: 2,
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: "500px",
                      overflowY: "auto",
                    }}
                  >
                    {APIData !== "" && APIData.includes("allocation") && (
                      <Presets
                        APIData={APIData_compare}
                        searchFormData={searchFormData_compare}
                        setSliceIsVisible={setSliceIsVisible_compare}
                        presetNameIndex={presetNameIndex_compare}
                        setPresetNameIndex={setPresetNameIndex_compare}
                        resetWeights={resetWeights_compare}
                        setTabsAvailable_Presets={setTabsAvailable_Presets}
                      />
                    )}
                    {/* Classification */}
                    <Typography id="legendTitle" component="p" variant="h5">
                      <DisplayClassification
                        APIData={APIData_compare}
                        sliceIsVisible={sliceIsVisible_compare}
                        weight={weight_compare}
                      />
                    </Typography>
                    <Legend
                      APIData={APIData_compare}
                      sliceIsVisible={sliceIsVisible_compare}
                      setSliceIsVisible={setSliceIsVisible_compare}
                      weight={weight_compare}
                      searchFormData={searchFormData_compare}
                    />
                  </Paper>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: "500px",
                      overflowY: "auto",
                    }}
                  >
                    <Box sx={{ mt: 2 }}>
                      <THRIVEGrid
                        APIData={APIData_compare}
                        sliceIsVisible={sliceIsVisible_compare}
                        setSliceIsVisible={setSliceIsVisible_compare}
                        weight={weight_compare}
                        setWeight={setWeight_compare}
                        selectAllSlices={selectAllSlices_compare}
                        setSelectAllSlices={setSelectAllSlices_compare}
                      />
                    </Box>
                  </Paper>
                </Grid>
              </>
            )}
          </Grid>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
    /* </ThemeProvider> */
  );
}

export default function Dashboard() {
  const history = useHistory();

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    console.log("localStorage.isLoggedIn: ", isLoggedIn);
    if (isLoggedIn !== "true") {
      history.push("/login");
    }

    const token = localStorage.getItem("token");
    const checkUserLoggedIn = async () => {
      console.log("checkUserLoggedIn: Testing if user is logged in... ");
      try {
        const response = await axios.post(
          serverDomain + "/php/isUserLoggedInAPI.php",
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
            timeout: 20000, // Set the timeout to 20000ms (20 seconds)
          }
        );
        if (response.data.success === 0) {
          if (response.data.message === "Unauthorised") {
            console.log("checkUserLoggedIn: API: User is not authorised.");
            history.push("/login");
          } else if (response.data.message === "User is not logged in.") {
            console.log("checkUserLoggedIn: API: User is not logged in.");
            history.push("/login", {
              logoutMessage: "You have been logged out.",
            });
          }
        }
        //else user is logged in.
        if (response.data.success === 1) {
          console.log("checkUserLoggedIn: API: User is logged in.");
        }
      } catch (error) {
        if (error.message.includes("timeout")) {
          console.error("checkUserLoggedIn: Request timed out.");
        } else if (
          error.message === "Network Error" ||
          error.message.includes("ERR_INTERNET_DISCONNECTED")
        ) {
          console.error(
            "Network is disconnected. Error checking if user is logged in."
          );
        } else {
          console.error(
            "checkUserLoggedIn: Error checking if user is logged in: ",
            error
          );
        }
      }
    };

    // Run the function once at first render
    checkUserLoggedIn();

    // Then run it every 30 seconds
    const intervalId = setInterval(checkUserLoggedIn, 30000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [history]);

  return <DashboardContent />;
}
