import React from "react";
import { loadCiambellaChartData } from "./ccjsv6_2";

/*********************************************************************************
 * Display Classification component
 */
function DisplayClassification({ APIData, sliceIsVisible, weight }) {
  //check if data is empty and return null if it is
  if (!APIData) {
    return null;
  }

  //var ClassData = loadCiambellaChartData(APIData, sliceIsVisible, weight);
  // use {} for 'instance', null for SPIScore, and {} searchFormData, not needed to get classifiaction from APIData...
  var ClassData = loadCiambellaChartData(
    {},
    APIData,
    sliceIsVisible,
    weight,
    null,
    {}
  );

  /* chartInstance,
  APIData,
  sliceIsVisible,
  weight,
  SPIScore,
  searchFormData */
  //check if ClassData is undefined and return null if it is
  if (!ClassData) {
    return null;
  }

  return <>Classification -&nbsp;{ClassData.datasets[0].class_name}</>;
}

export default DisplayClassification;
