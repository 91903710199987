//import ErrorSharp from "@mui/icons-material/ErrorSharp";
import {
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useState } from "react";
import axios from "axios";
import { serverDomain } from "./config";
import CircularProgress from "@mui/material/CircularProgress";
import { Recaptcha } from "./Recaptcha";
import Alert from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles({
  headerStyles: {
    paddingTop: "20",
    display: "flex",
    flexDirection: "row",

    width: "400",
    height: "400",
  },
});

//Success message component...
export const SuccessEmailSent = () => {
  const classes = useStyles();
  return (
    <>
      <Container maxWidth="sm" bgcolor="yellow">
        <Box component="header" sx={classes.headerStyles}>
          <CssBaseline />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                component="h1"
                fontWeight="600"
                align="center"
                marginTop={10}
                paddingRight={8}
              >
                Email sent. Please check your email and follow the instructions.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export const ResetPassword = () => {
  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = React.useState({});
  const [reCaptchaToken, setRecaptchaToken] = React.useState("");
  const [recaptchaRef, setRecaptchaRef] = React.useState("");

  const classes = useStyles();

  const handleCaptchaChange = (token, recaptchaRef) => {
    setRecaptchaToken(token);
    setErrors({
      ...errors,
      invalidRecaptcha: "",
    });
    setRecaptchaRef(recaptchaRef);
  };

  const handleCaptchaExpire = () => {
    setRecaptchaToken(null);
    setErrors({
      ...errors,
      invalidRecaptcha: "",
    });
  };

  const handleInputChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Regular expression for email validation...
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,}$/i;

    if (email === "") {
      setError("Email is required");
    } else if (!emailRegex.test(email)) {
      setError("Invalid email address");
    } else {
      // Email must be valid...

      //set spinner...
      setLoading(true);

      axios
        .post(serverDomain + "/php/sendreset.php", null, {
          params: {
            email: email,
            reCAPTCHA_token: reCaptchaToken,
          },
        }) //, timeout: 15000
        .then((res) => {
          console.log(res);
          if (res.data.success === 1) {
            //clear the spinner...
            setLoading(false);
            //set success to true to show the success message
            setSuccess(true);
          } else {
            console.log("send email to password sendreset.php failed", res);
            recaptchaRef.reset();
            setRecaptchaToken("");
            setLoading(false);

            //console.log(" after reset captcah token:", reCaptchaToken);
            // setInvalidDetails("Invalid Email or Password");
            // errors.invalid = "Invalid Email or Password";
            const errorMessage = res.data.message;
            if (errorMessage.includes("reCAPTCHA")) {
              setErrors({
                ...errors,
                invalidRecaptcha: errorMessage,
              });
            }
          }
        });
    }
  };

  return (
    <>
      {success ? (
        <SuccessEmailSent />
      ) : (
        <Container maxWidth="sm" bgcolor="yellow">
          <Box component="header" sx={classes.headerStyles}>
            <CssBaseline />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  component="h1"
                  fontWeight="600"
                  align="center"
                  marginTop={10}
                  paddingRight={8}
                >
                  Reset Password
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="h6" paddingX={5}>
                  Please enter your email address. You will receive an email
                  message with instructions on how to reset your password.
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3, mx: 8 }}
          >
            <Grid container spacing={2} paddingRight={6}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  autoFocus
                  id="email"
                  label="Email Address"
                  type="email"
                  name="email"
                  onChange={handleInputChange}
                  error={error ? true : false}
                  helperText={error}
                />
                <Grid item xs={12} marginTop="20px">
                  <Recaptcha
                    onCaptchaChange={handleCaptchaChange}
                    onCaptchaExpire={handleCaptchaExpire}
                  />
                </Grid>
                {errors.invalidRecaptcha ? (
                  <Alert severity="error">{errors.invalidRecaptcha}</Alert>
                ) : (
                  ""
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    height: "56px",
                    backgroundColor: theme.palette.skyBlue.main, // Use custom SkyBlue color
                    "&:hover": {
                      backgroundColor: theme.palette.darkBlue.main, // darker shade for hover state
                    },
                  }}
                  disabled={!reCaptchaToken}
                >
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: "#ffffff" }}
                      thickness={6}
                    />
                  ) : (
                    <span style={{ fontWeight: "bold" }}>Get New Password</span>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
    </>
  );
};
