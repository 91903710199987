// src/theme.js
import { createTheme } from "@mui/material/styles";

const ThriveBrandTheme = createTheme({
  palette: {
    primary: {
      main: "#1976d2", // the original blue color
    },
    secondary: {
      main: "#ff5722", // Customize your secondary color
    },
    darkBlue: {
      main: "#08386b",
    },
    darkGreen: {
      main: "#369482",
    },
    neonGreen: {
      main: "#5ce094",
    },
    lightGreen: {
      main: "#8ce3b0",
    },
    cream: {
      main: "#edebe3",
    },
    skyBlue: {
      main: "#549ec4",
    },
    purple: {
      main: "#5945a1",
    },
    magenta: {
      main: "#a86199",
    },
    coral: {
      main: "#f0828a",
    },
  },
  /* components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 12, // Example of customizing button styles
        },
      },
    },
  }, */
});

export default ThriveBrandTheme;
