import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { serverDomain } from "./config";
import axios from "axios";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";

export default function Presets({
  APIData,
  searchFormData,
  setSliceIsVisible,
  setPresetNameIndex,
  presetNameIndex,
  resetWeights,
  setTabsAvailable_Presets,
}) {
  // Maximum number of slices
  const MAXIMUM_NUMBER_OF_SLICES = 5000;

  //append the parameters to the URL
  //appends the key-value pairs from the params object as query parameters to the url object
  const appendParams = (url, params) => {
    Object.entries(params).forEach(([k, v]) => url.searchParams.append(k, v));
  };

  //init the loading state...
  const [loading, setLoading] = useState(false);
  const [presetOptions, setPresetOptions] = useState([]);

  //a message on the form to display if there is an error...
  const [errorMessage, setErrorMessage] = useState(false);

  // Ensure token is declared and initialized before use
  const token = localStorage.getItem("token");

  //make useEffect that responds to changes of PresetNameIndex and calls sendPresetName()
  useEffect(() => {
    getPresetOptions();
  }, [presetNameIndex, APIData]); //

  //only call sendPresetName when presetOptions changes and is not empty...
  useEffect(() => {
    //Make presetOptions has filled up before calling sendPresetName...
    if (presetOptions.length > 0) {
      sendPresetName();
    }
  }, [presetOptions]);

  function getPresetOptions() {
    // Ensure token is initialized before using it
    if (!token) {
      console.error("getPresetOptions(): Token is not initialized");
      return;
    }

    //get list of preset for level and classification
    const presetsUrl = new URL(serverDomain + "/php/presetOptions.php");

    //TODO: fix this in API
    //the classId field is named classSelected in the presetOptions API so
    //we create a new object and rename the key...

    // Create a new object with the key name changed
    const updatedSearchFormData = {
      ...searchFormData,
      classSelected: searchFormData.classId,
    };

    // Remove the old key
    delete updatedSearchFormData.classId;

    appendParams(presetsUrl, updatedSearchFormData);

    //console.log("FORMDATA in getPresetOptions", searchFormData);

    //set spinner...
    setLoading(true);

    //set the tabsAvailable_Presets state to false until the API response returns...
    setTabsAvailable_Presets(false);

    axios
      .get(presetsUrl, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 20000, // Set timeout to 20 seconds (20000 milliseconds)
      })
      .then((res) => {
        //headers
        console.log("From getEntities presetOptions.php");
        console.log("presetOptions.php token", token);
        console.log("presetOptions.php url: " + presetsUrl.toString());
        console.log("presetOptions.php res.data.preset []", res.data.preset);
        console.log("presetOptions.php updated object", updatedSearchFormData);

        //set the presetOptions state in the state
        setPresetOptions(res.data.preset);

        //clear the spinner...
        setLoading(false);

        //set the tabsAvailable_Presets state to true after the API response returns...
        setTabsAvailable_Presets(true);
      })
      .catch((error) => {
        setLoading(false); // Clear the spinner in case of error
        //set the tabsAvailable_Presets state to false if there was an error...
        setTabsAvailable_Presets(false);
        if (error.code === "ECONNABORTED") {
          console.error("Request timed out");
          setErrorMessage("The request timed out. Please try again later.");
        } else {
          console.error("getPresetOptions(): There was an error!", error);
          setErrorMessage("There are network issues, please try again later.");
        }
      });
  }

  //load options by calling API with searchData

  //send the preset name to the gridPreset.php API to get list of topics...
  const sendPresetName = async () => {
    //check if data is empty and return null if it is...
    if (!presetOptions || presetOptions.length === 0) {
      return null;
    }
    const presetAPIData = {
      dateTimeEndId: searchFormData.dateTimeEndId,
      dateTimeStartId: searchFormData.dateTimeStartId,
      classSelected: searchFormData.classId,
      level: searchFormData.levelId,
      feId: searchFormData.feId,
      wfId: searchFormData.wfId,
      entityId: searchFormData.entityId,
      name: presetOptions[presetNameIndex].name,
    };
    //log presetAPIData...
    console.log("presets, presetAPIData: ", presetAPIData);

    const url = new URL(serverDomain + "/php/gridPreset.php");
    appendParams(url, presetAPIData);
    console.log("Presets url: " + url.toString());

    //display spinner...
    setLoading(true);

    //set the tabsAvailable_Presets state to false until the API reponse returns...
    setTabsAvailable_Presets(false);

    //API call...
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 20000, // Set timeout to 20 seconds (20000 milliseconds)
      })
      .then((res) => {
        //log
        console.log(
          "From sendPresetName() in Presets.js API:gridPreset.php...",
          res
        );

        //clear the spinner...
        setLoading(false);

        //set the tabsAvailable_Presets state to true after the API response returns...
        setTabsAvailable_Presets(true);

        if (res.data.gridPreset1 === false) {
          //console.log("NOTOPICS No topics found for this preset... gridPreset1===false.");
          //console.log("NOTOPICS presetOptions: ", presetOptions);
          console.log("NOTOPICS res.data.gridPreset1: ", res.data.gridPreset1);
          //console.log("NOTOPICS res: ", res);
          //console.log("NOTOPICS presetAPIData.name", presetAPIData.name);

          //no presets found, set all slices to true...
          //Removing this set prop, fixes the problem of the slices being wiped out. But why? Maybe sendName should be called by a useEffect hook when presetOptions is filled.
          //var slices = new Array(MAXIMUM_NUMBER_OF_SLICES).fill(true);
          ////////////////setSliceIsVisible(slices);
          return;
        }

        //This is now done in Dashboard, by conditionally rendering the Presets component only when APIData is not empty andcontains an allocation field.
        //return if there are no preset topics returned by api - this happens when searching a blank/empty entity
        //if (!res.data.gridPreset1 || res.data.gridPreset1.length === 0) {
        //  return;
        //}

        for (const presetRow of res.data.gridPreset1) {
          console.log(presetRow);
          console.log(presetRow.class_path_id);
        }

        //find which topics from APIData are in gridPreset list and set the sliceIsVisible state...
        // Set all slices[] to false...
        var slices = new Array(MAXIMUM_NUMBER_OF_SLICES).fill(false);

        let index = 0;
        // Loop through each row in APIData...
        var objArray = JSON.parse(APIData); //convert JSON string to javascript object
        //var resObj = JSON.parse(res); //convert JSON string to javascript object
        for (const row of objArray.data) {
          console.log(row);
          console.log(row.class_path_id);

          // Remove pipes from class_path_id...
          const class_path_id_pipes_removed = row.class_path_id.replace(
            /\|/g,
            ""
          );
          console.log(
            "class_path_id_pipes_removed: ",
            class_path_id_pipes_removed
          );

          // Check if presetTopcs contains row.class_path_id...
          const found = res.data.gridPreset1.some(
            (topic) => topic.class_path_id === class_path_id_pipes_removed
          );
          if (found) {
            // Set sliceIsVisible[index] to true if APIData.data.class_path_id (row.class_path_id) exists in presetTopcis (res.class_path_id) --> (topic.class_path_id)...
            slices[index] = true;
          }
          index++;
        }

        console.log("index... ", index);

        //set sliceIsVisible state in Dashboard...

        setSliceIsVisible(slices);
        console.log("SLICES: ", slices);
        /* 
          const data = APIData.data;
          console.log('class_path_ids...');
          for (const row of data) {
            console.log(row.class_path_id);
          }
        */
      })
      .catch((error) => {
        setLoading(false); // Clear the spinner in case of error
        //set the tabsAvailable_Presets state to false if there was an error...
        setTabsAvailable_Presets(false);
        if (error.code === "ECONNABORTED") {
          console.error("Request timed out");
          setErrorMessage(
            "sendPresetName():The request timed out. Please try again later."
          );
        } else {
          console.error("There was an error!", error);
          setErrorMessage(
            "sendPresetName(): There are network issues, please try again later."
          );
        }
      });
  };

  //check if data is empty and return null if it is...
  if (!APIData) {
    return null;
  }

  //get the token from local storage...
  //const token = localStorage.getItem("token");

  function onChangePreset(option) {
    // set presetName state to the index of the selected option...
    setPresetNameIndex(option);
    //TODO: Ask the Project Lead about this feature. This may not be wanted, so it's commented out for now.
    //reset all weights to 1...
    //resetWeights();
    console.log(`Presets, Selected option: ${option}`);
    console.log("Preset name", presetOptions[option].name);
  }

  return (
    <>
      <Grid container justifyContent="space-between">
        {errorMessage && (
          <Box
            sx={{
              color: "black",
              backgroundColor: "pink",
              padding: "10px",
              marginBottom: "10px",
              width: "100%", // Ensure the error message takes full width
            }}
          >
            {errorMessage}{" "}
            <a
              href="/dashboard"
              style={{ color: "blue", textDecoration: "underline" }}
            >
              Reload the page
            </a>
          </Box>
        )}

        <Grid item xs={12} md={8}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              border: "0px solid green",
            }}
          >
            <TextField
              id="presets-selector"
              select
              label="Select preset"
              value={presetNameIndex}
              style={{
                width: "100%",
                border: "0px solid red",
                marginRight: "10px",
              }}
              onChange={(event) => {
                onChangePreset(event.target.value);
              }}
              disabled={!!errorMessage} // Disable when errorMessage is true
            >
              {presetOptions &&
                presetOptions.map((option, index) => (
                  <MenuItem key={index} value={index}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={24} />
              </div>
            ) : (
              ""
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
}
