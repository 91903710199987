import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Snackbar } from "@mui/material";
import TextField from "@mui/material/TextField";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import CssBaseline from "@mui/material/CssBaseline";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { serverDomain } from "./config";
import CircularProgress from "@mui/material/CircularProgress";

import { Alert, FormHelperText } from "@mui/material";
//import ReCAPTCHA from "react-google-recaptcha";
import { Recaptcha } from "./Recaptcha";
import axios from "axios";
import { useTheme } from "@mui/material/styles"; // Import useTheme

// Styled component for rounded corners
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "15px", // Adjust the border radius to your preference
  },
}));

const useStyles = makeStyles({
  customTooltip: {
    backgroundColor: "#1976d2",
    color: "white",
  },
  snackbarCustom: {
    "& .MuiSnackbarContent-root": {
      backgroundColor: "white",
      color: "blue",
      border: "1px solid blue",
    },
  },
});

//const theme = createTheme();

//set deafault values for the form fields
const defaultValues = {
  title: "",
  fullname: "",
  email: "",
  organization: "",
};

const ProfileEditorPopUp = ({ profileOpen, setProfileOpen }) => {
  const theme = useTheme();
  const token = localStorage.getItem("token");

  const [formValues, setFormValues] = React.useState(defaultValues);
  const [errorMessage, setErrorMessage] = useState("");

  const [errors, setErrors] = React.useState({});
  //const [success, setSuccess] = React.useState(false);
  const [recaptchaRef, setRecaptchaRef] = React.useState("");

  const [loading, setLoading] = useState(false);
  const [isFormDisabled, setFormDisabled] = useState(false);

  useEffect(() => {
    if (profileOpen) {
      // Only run the effect when profileOpen is true
      // Your code to run when the popup is opened
      console.log("Profile popup is now open.");

      //clear the values...
      setFormValues({
        title: "",
        fullname: "",
        email: "",
        organization: "",
      });

      //Re-enable the form
      setFormDisabled(false);

      //set spinner...
      setLoading(true);

      axios
        .get(
          serverDomain +
            "/php-login-registration-api/get-user-profile-data.php",
          {
            headers: { Authorization: `Bearer ${token}` },
            timeout: 20000, // Set timeout to 20000ms
          }
        )
        .then((response) => {
          //clear the spinner...
          setLoading(false);

          console.log("Profile data:", response.data);
          // Assuming the API returns an object that matches the structure of formValues
          setFormValues(response.data);
        })
        .catch((error) => {
          setLoading(false);
          if (axios.isCancel(error)) {
            setErrorMessage("Request canceled.");
          } else if (
            error.code === "ECONNABORTED" ||
            error.message.includes("timeout")
          ) {
            setErrorMessage(
              "Profile Data could not be loaded. Please try again later."
            );
          } else if (
            error.message === "Network Error" ||
            error.message.includes("ERR_INTERNET_DISCONNECTED")
          ) {
            console.log(
              "TNetwork is disconnected. Please check your internet connection and try again."
            );
            setErrorMessage(
              "Network is disconnected. Please check your internet connection and try again."
            );
          } else {
            console.log("There was an error fetching the profile data:", error);
            setErrorMessage("There was an error fetching the profile data.");
          }
        });
    }
  }, [profileOpen, token]); // Depend on profileOpen to run the effect only when the popup is opened

  const handleCaptchaChange = (token, recaptchaRef) => {
    setFormValues((currentForm) => {
      return { ...currentForm, token };
    });
    setErrors({
      ...errors,
      invalidRecaptcha: "",
    });
    setRecaptchaRef(recaptchaRef);
    console.log("Captcha value:", token);
  };
  const handleCaptchaExpire = () => {
    console.log("Captcha expired");
    setFormValues((currentForm) => {
      return { ...currentForm, token: null };
    });
    setErrors({
      ...errors,
      invalidRecaptcha: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validate = () => {
    console.log("inside validate..");
    const temp = {};
    temp.title = formValues.title ? "" : "Title is required";
    temp.fullName = formValues.fullname ? "" : "Full name is required";
    temp.email = formValues.email
      ? /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
          formValues.email
        )
        ? ""
        : "Email is not valid"
      : "Email is required";
    temp.organization = formValues.organization
      ? ""
      : "Organization is required";

    console.log("temp:", { ...temp });
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  const classes = useStyles();

  //const [profileOpen, setProfileOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);

  const handleClose = () => {
    setProfileOpen(false);
    setErrorMessage("");
  };

  const handleSaveProfile = () => {
    if (validate()) {
      // console.log(formValues);
      console.log(
        "Form values before post:  ",
        `             
        title: ${formValues.title}
        fullname: ${formValues.fullname}
        email: ${formValues.email}
        organization: ${formValues.organization}
        reCAPTCHA_token: ${formValues.token}`
      );

      // Create FormData instance
      const formData = new FormData();
      formData.append("title", formValues.title);
      formData.append("fullname", formValues.fullname);
      formData.append("email", formValues.email);
      formData.append("organization", formValues.organization);
      formData.append("reCAPTCHA_token", formValues.token);

      // Before making the axios post request, disable the form
      setFormDisabled(true);

      //set spinner...
      setLoading(true);

      // Make the axios post request
      axios
        .post(
          `${serverDomain}/php-login-registration-api/set-user-profile-data.php`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              // 'Content-Type': 'multipart/form-data' is not needed; axios sets it automatically
            },
            timeout: 20000, // Set timeout to 20000ms
          }
        )
        .then((response) => {
          //clear the spinner...
          setLoading(false);
          if (response.data.success) {
            console.log(response.data.message);
            //setSuccess(true);
            // Close the dialog and show the toast if form validates and post request is successful...
            setToastOpen(true); // Show toast
          } else {
            console.log("Form fields missing or database error: ", response);
            recaptchaRef.reset();
            setFormValues({
              ...formValues,
              token: "",
            });
            //props.history.push("/login");
            console.log("Not registered error message", response.data.message);
            const errorMessage = response.data.message;
            if (errorMessage.includes("mail")) {
              setErrors({
                ...errors,
                email: errorMessage,
              });
            }
            if (errorMessage.includes("organization")) {
              setErrors({
                ...errors,
                organization: errorMessage,
              });
            }
            if (errorMessage.includes("name")) {
              setErrors({
                ...errors,
                userName: errorMessage,
              });
            }
            if (errorMessage.includes("reCAPTCHA")) {
              setErrors({
                ...errors,
                invalidRecaptcha: errorMessage,
              });
            }
          }
        })
        .catch((error) => {
          //clear the spinner...
          setLoading(false);
          console.log("Error", error);
          if (axios.isCancel(error)) {
            setErrorMessage("Request canceled.");
          } else if (
            error.code === "ECONNABORTED" ||
            error.message.includes("timeout")
          ) {
            setErrorMessage(
              "Profile Data could not be saved. Please try again later."
            );
          } else if (
            error.message === "Network Error" ||
            error.message.includes("ERR_INTERNET_DISCONNECTED")
          ) {
            console.log(
              "TNetwork is disconnected. Please check your internet connection and try again."
            );
            setErrorMessage(
              "Network is disconnected. Please check your internet connection and try again."
            );
          } else {
            console.log("There was an error saving the profile data:", error);
            setErrorMessage(
              "There was an error saving the profile data. Please try again later."
            );
          }
        });
    }
  };

  const handleToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false); // Close toast
    setProfileOpen(false);
  };

  return (
    <div>
      <StyledDialog
        open={profileOpen}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography
            variant="body1"
            style={{ fontSize: "1.5rem" }}
            sx={{ pt: 3 }}
          >
            {loading && <Typography>Please wait...</Typography>}
            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
            Edit Profile
          </Typography>
          <Typography></Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {/* <ThemeProvider theme={theme}> */}
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                component="form"
                noValidate
                /* onSubmit={handleSubmit} */
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel
                        id="title-select-label"
                        style={{
                          color: errors.title
                            ? "#d32f2f"
                            : "rgba(0, 0, 0, 0.6)",
                        }}
                      >
                        Title
                      </InputLabel>
                      <Select
                        labelId="title-select-label"
                        id="title"
                        value={formValues.title}
                        label="Title"
                        type="text"
                        name="title"
                        onChange={handleInputChange}
                        error={errors.title ? true : false}
                        disabled={loading}
                      >
                        <MenuItem value={"Mr"}>Mr</MenuItem>
                        <MenuItem value={"Mrs"}>Mrs</MenuItem>
                        <MenuItem value={"Miss"}>Miss</MenuItem>
                        <MenuItem value={"Ms"}>Ms</MenuItem>
                        <MenuItem value={"Dr"}>Dr</MenuItem>
                        <MenuItem value={"Sir"}>Sir</MenuItem>
                      </Select>
                      <FormHelperText style={{ color: "#d32f2f" }}>
                        {errors.title}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="fullName"
                      label="Full Name"
                      name="fullname"
                      type="text"
                      value={formValues.fullname}
                      onChange={handleInputChange}
                      // error ={!!errors?.temp?.name}

                      error={errors.fullName ? true : false}
                      helperText={errors.fullName}
                      disabled={loading}
                      inputProps={{ maxLength: 64 }}
                      // onBlur={()=>!!errors?errors.fullName:""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={formValues.email}
                      onChange={handleInputChange}
                      error={errors.email ? true : false}
                      helperText={errors.email}
                      disabled={loading}
                      inputProps={{ maxLength: 64 }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      id="organization"
                      name="organization"
                      label="Institute or Organization"
                      type="text"
                      fullWidth
                      required
                      value={formValues.organization}
                      onChange={handleInputChange}
                      // error={errors.instituteOrOrganization}
                      error={errors.organization ? true : false}
                      helperText={errors.organization}
                      disabled={loading}
                      inputProps={{ maxLength: 64 }}
                    ></TextField>
                  </Grid>
                </Grid>

                <Grid item xs={12} marginTop="20px">
                  {/* <ReCaptchaV2 }
                sitekey={process.env.REACT_APP_SITE_KEY}
                onChange={handleToken}`
                onExpire={handleExpire}
            /> */}

                  <Recaptcha
                    onCaptchaChange={handleCaptchaChange}
                    onCaptchaExpire={handleCaptchaExpire}
                    // error={errors.reCAPTCHA_token ? true : false}
                    // helperText={errors.reCAPTCHA_token}
                  />
                </Grid>
                {errors.invalidRecaptcha ? (
                  <Alert severity="error">{errors.invalidRecaptcha}</Alert>
                ) : (
                  ""
                )}
              </Box>
            </Box>
          </Container>
          <Container>
            {/* <ReCAPTCHA
            sitekey="YOUR-SITE-KEY"
            onChange={onChange}
        /> */}
          </Container>
          {/* </ThemeProvider> */}
        </DialogContent>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveProfile}
          sx={{
            backgroundColor: theme.palette.skyBlue.main, // Use custom SkyBlue color
            "&:hover": {
              backgroundColor: theme.palette.darkBlue.main, // darker shade for hover state
            },
          }}
          disabled={loading || isFormDisabled || !formValues.token}
        >
          {loading ? <CircularProgress size={24} /> : "Save Changes"}
        </Button>
        <Snackbar
          open={toastOpen}
          autoHideDuration={6000}
          onClose={handleToastClose}
          message="Profile was updated"
          className={classes.snackbarCustom}
        />
      </StyledDialog>
    </div>
  );
};

export default ProfileEditorPopUp;
