import * as React from "react";
import { useEffect } from "react";
//import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
//import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
//import backgroundImage from "./thrive-start-header-bg-cr1.jpg";
import backgroundImage from "./thrive-startpage-generated.jpg";
import titleImage from "./Thrive-logo-nostrap-colour.png";
//import THRIVELogo from "./thrivelogo191x176_nofringe.png";
//import { lime, pink } from "@mui/material/colors";
//import { positions } from "@mui/system";
//import AutoFixOffSharp from "@mui/icons-material/AutoFixOffSharp";
//import THRIVELogo from './TP_logo_trans.png';
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { Recaptcha } from "./Recaptcha";
import Alert from "@mui/material/Alert";
import { serverDomain } from "./config";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useState } from "react";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://strive2thrive.earth/">
        Strive2Thrive.Earth
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

//const theme = createTheme();

export default function SignInSide() {
  const theme = useTheme(); // Access the theme

  const matches = useMediaQuery("(min-width:600px)");
  //Clear the localStorage each time the login page is shown
  useEffect(() => {
    //localStorage.removeItem('token');
    //localStorage.removeItem('isLoggedIn');
    localStorage.setItem("token", "");
    localStorage.setItem("isLoggedIn", "false");
  }, []);

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [stayLoggedin, setStayloggedin] = React.useState(false);
  //const [invalidDetails, setInvalidDetails] = React.useState("");
  const [reCaptchaToken, setRecaptchaToken] = React.useState("");
  const [recaptchaRef, setRecaptchaRef] = React.useState("");

  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const [logoutMessage, setLogoutMessage] = useState(
    location.state?.logoutMessage || ""
  );

  useEffect(() => {
    if (location.state?.logoutMessage) {
      setLogoutMessage(location.state.logoutMessage);
      history.replace({ ...location, state: {} });
    }
  }, [location, history]);

  const validate = () => {
    const temp = {};
    temp.username = username ? "" : "Username is required";
    temp.password = password ? "" : "Password is required";
    //console.log("temp:", { ...temp });
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "username") setUsername(value);
    if (name === "password") setPassword(value);
    if (name === "stayLoggedin") setStayloggedin(e.target.checked);
    setErrors({
      ...errors,
      invalid: "",
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const handleCaptchaReset = (e) => {
  //   console.log("in handlecaptcharesetne:", e);
  //   setRecaptchaToken("");
  // };
  const handleCaptchaChange = (token, recaptchaRef) => {
    // setFormValues((currentForm) => {
    //   return { ...currentForm, token };
    // });
    // console.log("recaptchaRef in handle captcha change:", recaptchaRef);
    // console.log("widgetId:", recaptchaRef._widgetId);
    setRecaptchaToken(token);
    setErrors({
      ...errors,
      invalidRecaptcha: "",
    });
    setRecaptchaRef(recaptchaRef);

    //console.log("Captcha value in login :", token);
  };
  const handleCaptchaExpire = () => {
    //console.log("Captcha expired");
    // setFormValues((currentForm) => {
    //   return { ...currentForm, token: null };
    // });
    setRecaptchaToken(null);
    setErrors({
      ...errors,
      invalidRecaptcha: "",
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    //const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    // console.log({
    //   email: data.get("email"),
    //   password: data.get("password"),
    // });
    if (validate()) {
      // console.log("Email:", email);
      // console.log("Password:", password);
      // console.log("stayloggedin:", stayLoggedin);
      // const s = stayLoggedin ? "on" : "";
      // console.log("s:", s);
      axios
        .post(serverDomain + "/php-login-registration-api/login.php", null, {
          params: {
            username: username,
            password,
            reCAPTCHA_token: reCaptchaToken,
            stayloggedin: stayLoggedin ? "on" : "",
          },
        })
        .then((response) => {
          if (response.data.success) {
            console.log("login success:", response);
            const { token } = response.data;
            // if (stayLoggedin) {
            //const token = "token test";
            localStorage.setItem("token", token);
            localStorage.setItem("isLoggedIn", "true");
            // console.log(
            //   "token from local storage:",
            //   localStorage.getItem("token")
            // );

            history.push("/dashboard");
            // <Redirect to="/dashboard" />;
          } else {
            // console.log("login failed", response);
            // console.log("captcah token:", reCaptchaToken);
            // // recaptchaRef.current?.reset(recaptchaRef._widgetId);
            // console.log("calling capthca reset....");
            recaptchaRef.reset();
            setRecaptchaToken("");

            //console.log(" after reset captcah token:", reCaptchaToken);
            // setInvalidDetails("Invalid Email or Password");
            // errors.invalid = "Invalid Email or Password";
            const errorMessage = response.data.message;
            if (
              errorMessage.includes("password") ||
              errorMessage.includes("mail") ||
              errorMessage.includes("Password")
            ) {
              setErrors({
                ...errors,
                invalid: "Invalid Username or Password",
              });
            }
            if (errorMessage.includes("reCAPTCHA")) {
              setErrors({
                ...errors,
                invalidRecaptcha: errorMessage,
              });
            }

            // const errorMessage = response.data.message;
            // if (errorMessage.includes("password")) {
            //   setErrors({
            //     ...errors,
            //     password: errorMessage,
            //   });
            // }
            // if (errorMessage.includes("mail")) {
            //   setErrors({
            //     ...errors,
            //     email: errorMessage,
            //   });
            // }
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  };

  // sx={{ height: '100vh' }}
  //<ThemeProvider theme={theme}>
  return (
    <Grid container component="main">
      <CssBaseline />
      {/* sx={{ height: "100vh" }} */}
      <Grid
        item
        xs={false}
        // sm={6}
        // md={8}
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Grid container spacing={2}>
          <CssBaseline />
          <Grid item xs={12} sm={6} md={8}>
            <Box
              mt={0}
              p={3}
              color="#08386B"
              paddingLeft="50px"

              // sx={{ width: "100%", height: "50%", position: "absolute" }}
            >
              <img
                src={titleImage}
                alt="Title"
                style={{ width: "70%", maxWidth: "400px" }}
              />
              {matches && (
                <>
                  <Typography
                    variant="h3"
                    fontWeight={800}
                    fontSize="33px"
                    mt={5}
                    sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                      padding: "10px",
                    }}
                  >
                    "THERE ARE NO SUSTAINABLE ENTITIES ON AN UNSUSTAINABLE
                    EARTH"
                  </Typography>
                  <Typography
                    component="h6"
                    fontWeight={800}
                    fontStyle="italic"
                    lineHeight={3}
                    sx={{
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                      padding: "10px",
                    }}
                  >
                    {" "}
                    -MORRIS D FEDELI, PROJECT LEAD
                  </Typography>
                </>
              )}
            </Box>
          </Grid>
          {/* <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ backgroundColor: "#fff", opacity: "0.8" }}
                  >
                    <Typography component="h1">Thrive quote</Typography>
                  </Grid> */}
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            component={Paper}
            elevation={2}
            square
            /* border="1px solid #f00" */
            sx={{ height: "100vh" }}
          >
            <CssBaseline />
            <Box
              sx={{
                // my: 8,
                mx: 4,
                "@media (min-width: 600px)": {
                  marginTop: 4, // Add margin to push the content down only when screen width is greater than 600px
                },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/*
                  <Avatar sx={{ m: 1, bgcolor: 'green' }}>
                  <LockOutlinedIcon />
                  </Avatar> 
                */}

              {/*               {matches && (
                <>
                  <img
                    src={THRIVELogo}
                    alt="THRIVE"
                    style={{ marginTop: 40, marginBottom: 40 }}
                  />
                </>
              )} */}

              {/* Display logout message if it exists */}
              {logoutMessage && (
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Alert severity="info">{logoutMessage}</Alert>
                </Grid>
              )}

              <Typography component="h1" variant="h5">
                Sign in to your account
              </Typography>

              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                {/* <TextField
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    disabled
                    error={errors.invalid ? true : false}
                    helperText={errors.invalid}
                  /> */}
                {errors.invalid ? (
                  <Alert severity="error">{errors.invalid}</Alert>
                ) : (
                  ""
                )}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Username"
                  name="username"
                  /*                     type="email"
                    autoComplete="email" */
                  autoFocus
                  onChange={handleInputChange}
                  error={errors.username ? true : false}
                  helperText={errors.username}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  onChange={handleInputChange}
                  error={errors.password ? true : false}
                  helperText={errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControlLabel
                  control={<Checkbox value="stayLoggedin" color="primary" />}
                  label="Stay logged in"
                  name="stayLoggedin"
                  onChange={handleInputChange}
                />
                <Grid item xs={12} marginTop="20px">
                  <Recaptcha
                    onCaptchaChange={handleCaptchaChange}
                    onCaptchaExpire={handleCaptchaExpire}
                  />
                </Grid>
                {/* <TextField
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    disabled
                    hidden
                    error={errors.invalidRecaptcha ? true : false}
                    helperText={errors.invalidRecaptcha}
                  /> */}
                {errors.invalidRecaptcha ? (
                  <Alert severity="error">{errors.invalidRecaptcha}</Alert>
                ) : (
                  ""
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    backgroundColor: theme.palette.skyBlue.main, // Use custom SkyBlue color
                    "&:hover": {
                      backgroundColor: theme.palette.darkBlue.main, // darker shade for hover state
                    },
                  }}
                  disabled={!reCaptchaToken}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="/get-new-password" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="/register" variant="body2">
                      Don't have an account? Sign Up
                    </Link>
                  </Grid>
                </Grid>
                <Copyright sx={{ mt: 5 }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
  //</ThemeProvider>
}
