import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Title from "./Title";
import { loadCiambellaChartData } from "./ccjsv6_2";
import axios from "axios";
import { serverDomain } from "./config";
import CircularProgress from "@mui/material/CircularProgress";

export default function EntitySPIandRank({
  APIData,
  sliceIsVisible,
  weight,
  searchFormData,
  numberOfEntities,
  setSPIScore,
  SPIScore,
  setRank,
  rank,
  setTabsAvailable_EntitySPIandRank,
}) {
  //initialize the totalScore state...
  //const [totalScore, setTotalScore] = useState(0);

  //initialize the rank state...
  //Move to dashboard: const [rank, setRank] = useState(0);

  //init the loading state...
  const [loading, setLoading] = useState(false);

  //a message on the form to display if there is an error...
  const [errorMessage, setErrorMessage] = useState(null);

  //append the parameters to the URL
  //appends the key-value pairs from the params object as query parameters to the url object
  const appendParams = (url, params) => {
    Object.entries(params).forEach(([k, v]) => url.searchParams.append(k, v));
  };

  //send the form data to the rank_score API...
  const sendFormData = async () => {
    const url = new URL(serverDomain + "/php/rank_score.php");
    appendParams(url, searchFormData);
    console.log("rank_score url: " + url.toString());
    //console.log(token);

    //display spinner...
    setLoading(true);

    //set the tabsAvailable_EntitySPIandRank state to false until the API response returns...
    setTabsAvailable_EntitySPIandRank(false);

    //reset the error message...
    setErrorMessage(false);

    //API call...
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 90000, // Set timeout to 90 seconds (90000 milliseconds) this is about the longest I have seen a rank score API call take
      })
      .then((res) => {
        //headers
        //console.log("From sendFormData() in EntitySPIandRank.js API:rank_score.php");
        //console.log(token);
        //console.log("url: " + url.toString());
        console.log(
          "From sendFormData() in EntitySPIandRank.js API:rank_score.php...",
          res,
          "totalScore: ",
          res.data.totalScore
        );

        //setTotalScore(res.data.totalScore); //set state in this component...
        setSPIScore(res.data.totalScore); //set state in parent component...
        setRank(res.data.rank); //set state in parent component...

        //console.log("API Data: ", res.data.data);
        //console.log("API Data success: ", res.data.success);
        //console.log("API Data to display: ", res.data);

        //clear the spinner...
        setLoading(false);

        //set the tabsAvailable_EntitySPIandRank state to true after the API response returns...
        setTabsAvailable_EntitySPIandRank(true);
      })
      .catch((error) => {
        //clear the spinner...
        setLoading(false);
        //set the tabsAvailable_EntitySPIandRank state to false if there was an error
        setTabsAvailable_EntitySPIandRank(false);
        if (error.code === "ECONNABORTED") {
          console.error("ENTRS: Request timed out");
          // Handle timeout error
          setErrorMessage(
            "The request timed out. The network is slow. Please try again later."
          );
        } else {
          console.error("ENTRS: There was an error!", error);
          // Handle other errors
          setErrorMessage("There are network issues, please try again later.");
        }
      });
  };

  //call sendFormData() when the component is mounted...
  useEffect(() => {
    //when useEfect is triggered log thte rank and score...
    console.log(
      "ERS: From useEffect in EntitySPIandRank.js... rank: ",
      rank,
      " Num of Entities: ",
      numberOfEntities,
      " SPIScore: ",
      SPIScore
    );

    //only get the rank and score if the SPIScore and rank is empty and APIData is not empty...
    if (SPIScore === null && rank === null && APIData) {
      console.log(
        "ERS: From useEffect in EntitySPIandRank.js... getting rank and score from APIData..."
      );
      sendFormData();
    } else {
      console.log(
        "ERS: From useEffect in EntitySPIandRank.js... rank and score already set..."
      );
    }
  }, [APIData, searchFormData]); // Empty dependency array means this effect will run once when the component is mounted

  //get the token from local storage...
  const token = localStorage.getItem("token");

  //check if data is empty and return null if it is...
  if (!APIData) {
    return null;
  }
  /* 
  var entityData = loadCiambellaChartData(
    APIData,
    sliceIsVisible,
    weight,
    SPIScore
  );
   */

  // use {} for 'instance', null for SPIScore, and {} searchFormData, not needed to get entityData from APIData...
  var entityData = loadCiambellaChartData(
    {},
    APIData,
    sliceIsVisible,
    weight,
    null,
    {}
  );
  //check if entityData is undefined and return null if it is...
  if (!entityData) {
    return null;
  }

  console.log("-------------------------------------------------------------");
  console.log("Entity ciambellaChartData: ", entityData);

  //check if searchFormData is empty and return null if it is...
  if (!searchFormData) {
    return null;
  }

  console.log("searchFormData: ", searchFormData);

  return (
    <>
      {entityData.datasets[0].entity_name ? (
        <>
          <Grid container justifyContent="space-between">
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={6}
              className="EntitySPIandRankPadding"
            >
              <Title>Entity name</Title>
              <Typography component="p" variant="h5">
                {entityData.datasets[0].entity_id} -{" "}
                {entityData.datasets[0].entity_name}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              xl={3}
              className="EntitySPIandRankPadding"
            >
              <Title>Rank</Title>
              {loading ? (
                <CircularProgress /> // This is the spinner that will be shown while loading
              ) : (
                <Typography component="p" variant="h4">
                  {rank || rank !== null
                    ? rank + " of " + numberOfEntities
                    : "No data"}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              xl={3}
              className="EntitySPIandRankPadding"
            >
              <Title>SPI Score</Title>
              {loading ? (
                <CircularProgress /> // This is the spinner that will be shown while loading
              ) : (
                <Typography component="p" variant="h4">
                  {typeof SPIScore === "number" || SPIScore !== null
                    ? SPIScore.toFixed(3)
                    : "No data"}
                </Typography>
              )}
            </Grid>

            {errorMessage && (
              <Box
                sx={{
                  color: "black",
                  backgroundColor: "pink",
                  padding: "10px",
                  marginBottom: "10px",
                  width: "100%", // Ensure the error message takes full width
                }}
              >
                {errorMessage}{" "}
                <a
                  href="/dashboard"
                  style={{ color: "blue", textDecoration: "underline" }}
                >
                  Reload the page
                </a>
              </Box>
            )}
          </Grid>
        </>
      ) : null}
    </>
  );
}
