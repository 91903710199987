import ErrorSharp from "@mui/icons-material/ErrorSharp";
import {
  Button,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { serverDomain } from "./config";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Recaptcha } from "./Recaptcha";
import Alert from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles({
  headerStyles: {
    paddingTop: 20,
    display: "flex",
    flexDirection: "row",
    width: 400,
    height: 400,
  },
});

//Success message component...
export const SuccessPasswordSet = () => {
  const classes = useStyles();
  return (
    <>
      <Container maxWidth="sm" bgcolor="yellow">
        <Box component="header" sx={classes.headerStyles}>
          <CssBaseline />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                component="h1"
                fontWeight="600"
                align="center"
                marginTop={10}
                paddingRight={8}
              >
                Your password has been successfully reset. <br />
                <br />
                <Link to="/login">Click here to login</Link>.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export const ChoosePassword = () => {
  const theme = useTheme();
  const [password1, setPasword1] = useState("");
  const [error1, setError1] = useState("");
  const [password2, setPasword2] = useState("");
  const [error2, setError2] = useState("");
  const [passwordsDoNotMatch, setPasswordsDoNotMatch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const [errors, setErrors] = React.useState({});
  const [reCaptchaToken, setRecaptchaToken] = React.useState("");
  const [recaptchaRef, setRecaptchaRef] = React.useState("");

  const classes = useStyles();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  console.log("email from url: " + email);
  const mailtoken = queryParams.get("mailtoken");
  console.log("mailtoken from url: " + mailtoken);

  const handleCaptchaChange = (token, recaptchaRef) => {
    setRecaptchaToken(token);
    setErrors({
      ...errors,
      invalidRecaptcha: "",
    });
    setRecaptchaRef(recaptchaRef);
  };

  const handleCaptchaExpire = () => {
    setRecaptchaToken(null);
    setErrors({
      ...errors,
      invalidRecaptcha: "",
    });
  };

  const handleInputChange = (e) => {
    //console.log("value:", value);

    // Regular expression for password validation
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d]).{12,}$/;

    // Validate the password
    if (!passwordRegex.test(e.target.value)) {
      setError1(
        "Password must be at least 12 characters long and should contain at least one uppercase letter, one lowercase letter, one number and one special character"
      );
    } else {
      setError1("Password is valid");
    }
    //DoPasswordsMatch();
    setPasword1(e.target.value);
  };

  const handleInputChange2 = (e) => {
    //console.log("value:", value);

    // Regular expression for password validation
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^A-Za-z\d]).{12,}$/;

    // Validate the password
    if (!passwordRegex.test(e.target.value)) {
      setError2(
        "Password must be at least 12 characters long and should contain at least one uppercase letter, one lowercase letter, one number and one special character"
      );
    } else {
      setError2("Password is valid");
    }
    //DoPasswordsMatch();
    setPasword2(e.target.value);
  };

  useEffect(() => {
    DoPasswordsMatch();
  }, [password1, password2, error1, error2]); // dependencies

  const DoPasswordsMatch = () => {
    if (password1 === password2 && password1 !== "" && password2 !== "") {
      if (error1 === "Password is valid" && error2 === "Password is valid") {
        setPasswordsDoNotMatch(false);
      }
    } else {
      setPasswordsDoNotMatch(true);
    }
  };

  const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);
  const handleClickShowPassword2 = () => setShowPassword2(!showPassword2);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //set spinner...
    setLoading(true);

    axios
      .post(serverDomain + "/php/reset.php", null, {
        params: {
          email: email,
          mailtoken: mailtoken,
          password: password1,
          reCAPTCHA_token: reCaptchaToken,
        },
      }) //, timeout: 15000
      .then((res) => {
        console.log(res);
        if (res.data.success === 1) {
          //clear the spinner...
          setLoading(false);
          setSuccess(true);
        } else {
          console.log("send password to reset.php failed", res);
          recaptchaRef.reset();
          setRecaptchaToken("");
          setLoading(false);

          //console.log(" after reset captcah token:", reCaptchaToken);
          // setInvalidDetails("Invalid Email or Password");
          // errors.invalid = "Invalid Email or Password";
          const errorMessage = res.data.message;
          if (errorMessage.includes("reCAPTCHA")) {
            setErrors({
              ...errors,
              invalidRecaptcha: errorMessage,
            });
          }
        }
      });
  };

  return (
    <>
      {success ? (
        <SuccessPasswordSet />
      ) : (
        <Container maxWidth="sm" bgcolor="yellow">
          <Box component="header" sx={classes.headerStyles}>
            <CssBaseline />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  component="h1"
                  fontWeight="600"
                  align="center"
                  marginTop={10}
                  paddingRight={8}
                >
                  Choose A New Password
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="h6" paddingX={5}>
                  Please choose a new password.
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3, mx: 8 }}
          >
            <Grid container spacing={2} paddingRight={6}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  autoFocus
                  id="password1"
                  label="Choose a password"
                  type={showPassword1 ? "text" : "password"}
                  name="password1"
                  onChange={handleInputChange}
                  error={
                    error1 === "" || error1 === "Password is valid"
                      ? false
                      : true
                  }
                  helperText={error1}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor:
                          error1 === "Password is valid" ? green[500] : null,
                      },
                      "&:hover fieldset": {
                        borderColor:
                          error1 === "Password is valid" ? green[500] : null,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor:
                          error1 === "Password is valid" ? green[500] : null,
                      },
                    },
                    "& .MuiFormLabel-root": {
                      color: error1 === "Password is valid" ? green[500] : null,
                    },
                    "& .MuiFormHelperText-root": {
                      color: error1 === "Password is valid" ? green[500] : null,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword1}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword1 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <br />
            <br />
            <Grid container spacing={2} paddingRight={6}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="password2"
                  label="Type password again"
                  type={showPassword2 ? "text" : "password"}
                  name="email2"
                  onChange={handleInputChange2}
                  error={
                    error2 === "" || error2 === "Password is valid"
                      ? false
                      : true
                  }
                  helperText={error2}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor:
                          error2 === "Password is valid" ? green[500] : null,
                      },
                      "&:hover fieldset": {
                        borderColor:
                          error2 === "Password is valid" ? green[500] : null,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor:
                          error2 === "Password is valid" ? green[500] : null,
                      },
                    },
                    "& .MuiFormLabel-root": {
                      color: error2 === "Password is valid" ? green[500] : null,
                    },
                    "& .MuiFormHelperText-root": {
                      color: error2 === "Password is valid" ? green[500] : null,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
                <Typography
                  component="h6"
                  paddingX={0}
                  paddingTop={2}
                  style={{
                    fontWeight: "bold",
                    color: passwordsDoNotMatch ? "red" : "green",
                  }}
                >
                  {passwordsDoNotMatch === true
                    ? "Passwords Do Not Match"
                    : "Passwords Match"}
                </Typography>
                <Grid item xs={12} marginTop="20px">
                  <Recaptcha
                    onCaptchaChange={handleCaptchaChange}
                    onCaptchaExpire={handleCaptchaExpire}
                  />
                </Grid>
                {errors.invalidRecaptcha ? (
                  <Alert severity="error">{errors.invalidRecaptcha}</Alert>
                ) : (
                  ""
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    height: "56px",
                    backgroundColor: theme.palette.skyBlue.main, // Use custom SkyBlue color
                    "&:hover": {
                      backgroundColor: theme.palette.darkBlue.main, // darker shade for hover state
                    },
                  }}
                  disabled={passwordsDoNotMatch || !reCaptchaToken}
                >
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: "#ffffff" }}
                      thickness={6}
                    />
                  ) : (
                    <span style={{ fontWeight: "bold" }}>Set New Password</span>
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
    </>
  );
};
