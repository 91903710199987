import * as React from "react";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { serverDomain } from "./config";
import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  customTooltip: {
    backgroundColor: "#1976d2",
    color: "white",
  },
});

export function MenuItems({ setProfileOpen }) {
  const history = useHistory();
  const classes = useStyles();

  const handleProfileClick = () => {
    console.log("Profile clicked");
    setProfileOpen(true);
  };

  const handleLogout = async () => {
    console.log("Logging out...");
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        serverDomain + "/php-login-registration-api/logUserOut.php",
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.success === 1) {
        history.push("/login", { logoutMessage: "You have logged out." }); // redirect to /login with state
      }
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  return (
    <div>
      <ListItemButton onClick={handleProfileClick}>
        <Tooltip title="Profile" classes={{ tooltip: classes.customTooltip }}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Profile" />
      </ListItemButton>

      <ListItemButton onClick={handleLogout}>
        <Tooltip title="Log out" classes={{ tooltip: classes.customTooltip }}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Log out" />
      </ListItemButton>
    </div>
  );
}
