//const serverDomain = 'thrive-v3-backend.azurewebsites.net';
//const serverDomain = 'http://20.211.161.154/thrive-v3-backend/src';
//const serverDomain = 'https://platform-v3.strive2thrive.earth/thrive-v3-backend/src';
//const serverDomain = "http://20.5.48.8/thrive-v3-backend/src"; //azure AZ1 VM-V3 IP
const serverDomain = "https://api-v3.strive2thrive.earth/src"; //azure AZ1 VM-V3 subdomain with https

// This is the domain that the snippet code will use to make requests
// to the backend. It is used by the snippet code in SnippetPopUp.js
const platformDomain = "https://platform-v3.strive2thrive.earth/";

export { serverDomain, platformDomain };
