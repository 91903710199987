// Ciambella Chart JS for React - v6.2 [Filename: ccjsv6_2.js]
// Created: 12-10-2023
// Author: Geoff R. Williams
// Copyright The THRIVE Project - https://strive2thrive.earth

// --- NEW --- This version uses a function to create
//             instance specific data and variables.

// This version filters the alloc data into VisibleAlloc for slices that are visible
// It draws the slices, slicenumbers, and labels by looping forward from first elelement to last element
// of visibleAlloc and visibleColours arrays.
// It contains loadCiambellaChartData() function to load the chart data from a JSON file.
// To solve the bug where MCS aggregate has 'total_score' when the other classifications have 'score',
// by doing a string replace on the API data using: .replace(/total_score/g, 'score');
// it detects if it's doing an aggregate by searching the data for no "allocation" field.
// and then normalise the values by dividing 1 by the value, so they fit inside the doughnut.
// the loadCiambellaChartData() function accepts the sliceIsVisible array as a parameter

//The data object has a strange shape with datasets[] and labels[] as the chart was originally designed
//to work with chart.js library and this is how it needed the data to be.
function createCiambellaChartData() {
  return {
    datasets: [
      {
        backgroundColor: [],
        data: [],
        alloc: [],
        inner: [],
        outer: [],
        impact: [],
        spi: [],
        spiScore: 0,
      },
    ],
    labels: [],
  };
}

// This tells ESLint that snippetData is a global variable and it should not warn about it being undefined... It gets set in a script tag by HTML snippet code if it is being used
/* global snippetData */

// Define a function to create a new chart instance
export function createChartInstance() {
  return {
    canvas: null,
    ctx: null,
    showSpiLabels: true,
    showSliceNumbers: false,
    chartCanvasBackgroundColor: "white",
    centerX: null,
    centerY: null,
    radiusScaleFactor: 0.55,
    radius: null,
    holeRadius: null,
    sliceNumberDistanceFromCurcumference: null,
    holeColour: "white",
    spiLabelFontSize: "10px Arial",
    spiLabelDistanceFromDoughnut: 60,
    maxNumberOfDecimalPlacesInSpiLabels: 3,
    spiLabelWidth: 55,
    spiInnerLabel: "black",
    XORLineDrawingMode: false,
    linesColour: "#00f",
    lineWidth: 2,
    DataDotRadius: 3,
    dotFillColour: "#ff0",
    firstDotFillColour: "#0f0",
    lastDotFillColour: "#f00",
    useColoursForStartAndEndDots: true,
    spiScoreFontFamily: "arial",
    spiInnerLabelFontFamily: "arial",
    URLLabelFontFamily: "arial",
    spiScoreFontSize: "18",
    spiInnerLabelFontSize: "10",
    URLLabelFontSize: "10",
    spiInnerLabelDistanceFromCenter: 0,
    hoveredSlice: null,
    agg: null,
    sliceIsVisible: new Array(5000).fill(true),
    ciambellaChartData: createCiambellaChartData(),
  };
}

// Define a function to initialize the chart instance
export function initChart(instance, localCtx, localCanvasRef) {
  instance.ctx = localCtx;
  if (localCanvasRef) {
    instance.canvas = localCanvasRef.current;

    // Calculate and set centerX and centerY
    instance.centerX = instance.canvas.width / 2;
    instance.centerY = instance.canvas.height / 2;

    // Calculate and set radius, holeRadius, and sliceNumberDistanceFromCurcumference
    instance.radius =
      Math.min(instance.centerX, instance.centerY) * instance.radiusScaleFactor;
    instance.holeRadius = instance.radius * 0.45; // Adjust the factor to control the size of the hole
    instance.sliceNumberDistanceFromCurcumference = instance.radius * 2 - 40; // Adjust the distance to control the distance of the label from the circumference of the doughnut's circumference

    instance.spiInnerLabelDistanceFromCenter = 0;
    if (!instance.showSpiLabels) {
      instance.spiInnerLabelDistanceFromCenter = 10; // Adjust the distance to control the distance of the label from the center of the chart
    }

    console.log("ccjsv6_2: InitChart was run. instance.ctx = ", instance.ctx);
    console.log(
      "ccjsv6_2: instance.canvas was set. instance.canvas = ",
      instance.canvas
    );
    console.log("ccjsv6_2: instance object initialised. instance = ", instance);
    console.log("ccjsv6_2: instance.centerX = ", instance.centerX);
    console.log("ccjsv6_2: instance.centerY = ", instance.centerY);
    console.log("ccjsv6_2: instance.radius = ", instance.radius);
    console.log("ccjsv6_2: instance.holeRadius = ", instance.holeRadius);
    console.log(
      "ccjsv6_2: instance.sliceNumberDistanceFromCurcumference = ",
      instance.sliceNumberDistanceFromCurcumference
    );
  }
}

// Define a function to load the chart data
export function loadCiambellaChartData(
  instance,
  APIresponseObj,
  slices,
  weight,
  SPIScore,
  searchFormData
) {
  console.log("ccjsv6_2: loadCiambellaChartData was run.");
  console.log("ccjsv6_2: APIresponseObj = ", APIresponseObj);
  if (Object.keys(APIresponseObj).length === 0) {
    return;
  }

  if (slices.length === 0) {
    return;
  }

  instance.sliceIsVisible = slices;

  instance.agg = false;
  if (
    typeof APIresponseObj === "string" &&
    !APIresponseObj.includes("allocation")
  ) {
    instance.agg = true;
  }

  if (typeof APIresponseObj === "string") {
    APIresponseObj = APIresponseObj.replace(/total_score/g, "score");
    APIresponseObj = APIresponseObj.replace(/legend_id/g, "topic_name");
  }

  let objArray;
  try {
    objArray = JSON.parse(APIresponseObj);
  } catch (error) {
    console.error("Error parsing API response:", error);
    return;
  }

  const rows = objArray.data;

  instance.ciambellaChartData = createCiambellaChartData();

  var allocation = [];
  var inner = [];
  var outer = [];
  var impact = [];
  var score = [];
  var total_score = [];
  var topicName = [];
  var topicNumber = [];
  let topicNumTemp = 0; // Initialize with default value

  //clear the data object...
  instance.ciambellaChartData.datasets[0].data = [];
  instance.ciambellaChartData.datasets[0].alloc = [];
  instance.ciambellaChartData.datasets[0].inner = [];
  instance.ciambellaChartData.datasets[0].outer = [];
  instance.ciambellaChartData.datasets[0].impact = [];
  instance.ciambellaChartData.datasets[0].spi = [];
  instance.ciambellaChartData.datasets[0].total_score = [];
  instance.ciambellaChartData.labels = [];
  instance.ciambellaChartData.datasets[0].entity_id = "";
  instance.ciambellaChartData.datasets[0].entity_name = "";
  instance.ciambellaChartData.datasets[0].backgroundColor = [];

  let i = 0;
  for (const c in rows) {
    // Process each row and populate instance.ciambellaChartData
    allocation[i] = parseFloat(rows[i].allocation);
    inner[i] = parseFloat(rows[i].inner_limit);
    outer[i] = parseFloat(rows[i].outer_limit);
    impact[i] = parseFloat(rows[i].impact);

    //set the score[] to API response "score" field, if it exists...
    try {
      score[i] = parseFloat(rows[i].score);
    } catch (err) {
      score[i] = 0;
    }

    //set the total_score[] to API response "total_score" field, if it exists...
    try {
      total_score[i] = parseFloat(rows[i].total_score);
      //console.log("total_score[i]: " + total_score[i]);
    } catch (err) {
      total_score[i] = 0;
    }

    /* console.log(
      "after finally total_score[i]: " +
        instance.ciambellaChartData.datasets[0].total_score[i]
    ); */

    //set topicName[] to API response "topic_name" field, if it exists, else set it to "legend_id"...
    try {
      topicName[i] = rows[i].topic_name;
    } catch (err) {
      topicName[i] = rows[i].legend_id;
    }

    topicNumTemp = rows[i].class_path_id;

    //split string into array...
    topicNumTemp = topicNumTemp.split("|");

    //show how many elements were split...
    //console.log("class_path_id has: " + topicNumTemp.length + " elements");

    // ***** output topicNumTemp array (all the elements that were split out from rows[i].class_path_id)...
    for (let j = 0; j < topicNumTemp.length; j++) {
      //console.log("topicNumTemp[" + j + "]: " + topicNumTemp[j]);
    }

    //console.log("topicNumTemp[topicNumTemp.length-1]: "+topicNumTemp[topicNumTemp.length-1]);

    //set topic number as last element in array (the last thing in the string after the last pipe char | )...
    topicNumber[i] = topicNumTemp[topicNumTemp.length - 1];

    //console.log("topicNumTemp: "+topicNumTemp);

    //Set the classification name to the first element in the array ...
    instance.ciambellaChartData.datasets[0].class_name = topicNumTemp[0];

    /* 
    console.log("allocation: " + allocation[i]);
    console.log("inner: " + inner[i]);
    console.log("outer: " + outer[i]);
    console.log("impact: " + impact[i]);
    console.log("SPi: " + score[i]);
    console.log("topicName: " + topicName[i]);
    console.log("topicNumber: " + topicNumber[i]);
    */

    //place the extracted data into the instance.ciambellaChartData object...
    instance.ciambellaChartData.datasets[0].data[i] = allocation[i];
    instance.ciambellaChartData.datasets[0].alloc[i] = allocation[i];
    instance.ciambellaChartData.datasets[0].inner[i] = inner[i];
    instance.ciambellaChartData.datasets[0].outer[i] = outer[i];
    instance.ciambellaChartData.datasets[0].impact[i] = impact[i];

    console.log(
      "instance.ciambellaChartData.datasets[0].alloc[i]: ",
      instance.ciambellaChartData.datasets[0].alloc[i]
    );

    //set the data object spi[], if it exists, to the score[] value...
    try {
      instance.ciambellaChartData.datasets[0].spi[i] = score[i];
    } catch (err) {
      instance.ciambellaChartData.datasets[0].spi[i] = 0;
    }

    //set the data object total_score[], if it exists, to the total_score[] value...
    try {
      instance.ciambellaChartData.datasets[0].total_score[i] = total_score[i];
    } catch (err) {
      instance.ciambellaChartData.datasets[0].total_score[i] = 0;
    }

    //make tooltip labels and legend labels have classifaction id and topic name at front...
    if (
      instance.ciambellaChartData.datasets[0].class_name === "GRIS" ||
      instance.ciambellaChartData.datasets[0].class_name === "SDGS" ||
      instance.ciambellaChartData.datasets[0].class_name === "MCS"
    ) {
      instance.ciambellaChartData.labels[i] =
        topicNumber[i] + " - " + topicName[i];
    } else if (instance.ciambellaChartData.datasets[0].class_name === "WSSI") {
      //WSSI is slightly different...

      //init  instance.ciambellaChartData.labels[i] to be blank...
      instance.ciambellaChartData.labels[i] = "";

      //set instance.ciambellaChartData.labels[i] to be the topic number...
      for (let k = topicNumTemp.length - 1; k > 0; k--) {
        instance.ciambellaChartData.labels[i] =
          instance.ciambellaChartData.labels[i] +
          topicNumTemp[topicNumTemp.length - k];
      }

      //add the topic name...
      instance.ciambellaChartData.labels[i] =
        instance.ciambellaChartData.labels[i] + " - " + topicName[i];
    }

    i++;
  }

  instance.ciambellaChartData.datasets[0].entity_id = rows[0].entity_id;
  instance.ciambellaChartData.datasets[0].entity_name = rows[0].entity_name;

  let allocIsEmpty = true;
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].allocation !== undefined && rows[i].allocation !== null) {
      allocIsEmpty = false;
      break;
    }
  }

  let classColours = {
    GRIS: [
      "#D0D0D0",
      "#C0C0C0",
      "#B0B0B0",
      "#8FC5FE",
      "#72B3F6",
      "#55A2EE",
      "#3990E6",
      "#1C7FDE",
      "#006ED6",
      "#C0E6B5",
      "#A4E4B5",
      "#89D2A6",
      "#6DC097",
      "#52AF87",
      "#369D78",
      "#1B8B69",
      "#007A5A",
      "#FDA1A0",
      "#F69796",
      "#F08E8D",
      "#EA8583",
      "#E47C7A",
      "#DE7370",
      "#D76967",
      "#D1605E",
      "#CB5754",
      "#C54E4B",
      "#BF4541",
      "#B93C38",
      "#B2322F",
      "#AC2925",
      "#A6201C",
      "#A01712",
      "#9A0E09",
      "#940500",
      "#840300",
    ],
    SDGS: [
      "#E5233B",
      "#DCA63A",
      "#4C9E38",
      "#C51A2D",
      "#FF3A20",
      "#25BDE2",
      "#FBC30A",
      "#A21A42",
      "#FD6824",
      "#DD1367",
      "#FD9D25",
      "#C08A30",
      "#3F7E45",
      "#3F7E45",
      "#56C02A",
      "#00689D",
      "#1A486A",
    ],
    WSSI: [
      "#213261",
      "#293E78",
      "#30498E",
      "#3855A5",
      "#4061BB",
      "#476CD2",
      "#4F78E8",
      "#D28D09",
      "#D69009",
      "#DA9209",
      "#DE950A",
      "#E2980A",
      "#E69B0A",
      "#EB9D0A",
      "#EFA00A",
      "#F3A30A",
      "#F7A60B",
      "#FBA80B",
      "#4B7834",
      "#4F7F37",
      "#53863A",
      "#588C3D",
      "#5C9340",
      "#609A43",
      "#64A146",
      "#69A748",
      "#6DAE4B",
      "#71B54E",
      "#75BC51",
      "#79C254",
      "#7EC957",
      "#82D05A",
      "#86D75D",
      "#8ADD60",
      "#8FE463",
      "#93EB66",
      "#97F269",
      "#9BF86C",
      "#C21727",
      "#C61728",
      "#CA1829",
      "#CE1829",
      "#D2192A",
      "#D6192B",
      "#DA1A2C",
      "#DE1A2D",
      "#E31B2E",
      "#E71B2E",
      "#EB1C2F",
      "#EF1C30",
      "#F31D31",
      "#F71D32",
      "#FB1E32",
      "#EB6412",
      "#EE6512",
      "#F16612",
      "#F46813",
      "#F66913",
      "#F96A13",
      "#FC6B13",
    ],
    MCS: [
      "#D6E5BD",
      "#B3EA54",
      "#86AC44",
      "#ADCBE7",
      "#79B9F5",
      "#207ED6",
      "#E7B9B8",
      "#D5817F",
      "#C24542",
    ],
  };

  // *** old condition *** if there are no allocation values or if searchFormData is not empty and the levelId is greater than 2, then just set all the values to 10...
  //if (allocIsEmpty || (searchFormData && searchFormData.levelId > 2)) {

  // if allocIsEmpty or if searchFormData is not empty and searchFormData.levelId > 2 and if there are more rows than there are colours available for the chosen classification...
  console.log(
    "ccjsv6_2.1: classColours[instance.ciambellaChartData.datasets[0].class_name].length: " +
      classColours[instance.ciambellaChartData.datasets[0].class_name].length
  );
  console.log("ccjsv6_2.1: rows.length: " + rows.length);
  console.log("ccjsv6_2.1: searchFormData.levelId: " + searchFormData.levelId);
  console.log("ccjsv6_2.1: searchFormData: " + searchFormData);
  console.log("ccjsv6_2.1: allocIsEmpty: " + allocIsEmpty);
  console.log(
    "ccjsv6_2.1: Object.keys(searchFormData).length: " +
      Object.keys(searchFormData).length
  );

  if (
    allocIsEmpty ||
    (searchFormData &&
      Object.keys(searchFormData).length > 0 &&
      searchFormData.levelId > 2 &&
      rows.length >
        classColours[instance.ciambellaChartData.datasets[0].class_name].length)
  ) {
    for (let i = 0; i < rows.length; i++) {
      //just a fixed amount for each element. Here I'm using 10, but it could be any positive number.
      instance.ciambellaChartData.datasets[0].data[i] = 10;
      instance.ciambellaChartData.datasets[0].alloc[i] = 10;
    }
    instance.ciambellaChartData.datasets[0].backgroundColor = rainbowColours(
      rows.length
    );
  } else {
    // Use classification colours
    //set backgroundColour array classColours object using instance.ciambellaChartData.datasets[0].class_name as the key...
    instance.ciambellaChartData.datasets[0].backgroundColor =
      classColours[instance.ciambellaChartData.datasets[0].class_name];

    /* 
    console.log(
      "auto detect array... instance.ciambellaChartData.datasets[0].backgroundColor: " +
        instance.ciambellaChartData.datasets[0].backgroundColor
    );
    */
  }

  if (instance.agg === true) {
    let highestValue = Math.max(...instance.ciambellaChartData.datasets[0].spi);
    //console.log("highest total_score: ");
    //console.log(highestValue);
    //normalise the spi values...
    //doing this will make the spi values in the chart all be between 0 and 1 and you'll lose the total_score values for the label (which are much higher than 1, sometimes hundreds).
    for (let i = 0; i < rows.length; i++) {
      instance.ciambellaChartData.datasets[0].spi[i] =
        instance.ciambellaChartData.datasets[0].spi[i] / highestValue;
    }
  }

  if (SPIScore !== null && SPIScore !== undefined) {
    instance.ciambellaChartData.datasets[0].spiScore = SPIScore;
  }

  // loop thought ciambellaChartData and muliply each spi[] value by weight[]
  instance.ciambellaChartData.datasets[0].spi =
    instance.ciambellaChartData.datasets[0].spi.map(
      (value, i) => value * weight[i]
    );

  /* 
  console.log(
    "ccjsv6_2: loadCiambellaChartData was run. instance.ciambellaChartData = ",
    instance.ciambellaChartData
  );
  */
  console.log("ccjsv6_2: loadCiambellaChartData was run.");
  console.log(
    "ccjsv6_2: instance.ciambellaChartData = ",
    instance.ciambellaChartData
  );
  return instance.ciambellaChartData;
}

// Define a function to paint the chart
export function paint(instance) {
  if (typeof snippetData !== "undefined" && snippetData) {
    instance.ciambellaChartData = snippetData;
  }
  if (instance.ciambellaChartData.datasets[0].alloc.length === 0) {
    return;
  }
  drawDoughnut(instance);
  if (instance.showSliceNumbers) {
    drawSliceNumbers(instance);
  }
  if (instance.showSpiLabels) {
    drawSpiValuesAndClassIdInLabelsWithSwatch(instance);
  }
  drawInnerText(instance);
  drawSpiDotsAndLines(instance);

  console.log("ccjsv6_2: Paint() was called");
}

// Define a function to clear the chart
export function clearChart(instance) {
  if (instance.ctx === null) {
    return;
  }
  instance.ctx.fillStyle = instance.chartCanvasBackgroundColor;
  instance.ctx.clearRect(0, 0, instance.canvas.width, instance.canvas.height);
  instance.ciambellaChartData.datasets[0].alloc.length = 0;
}

// Define a function to draw the doughnut
function drawDoughnut(instance) {
  if (instance.ciambellaChartData.datasets[0].alloc.length === 0) {
    return;
  }
  var sliceStartAngle = 0;
  instance.hoveredSlice = -1;

  // Filter visibleAlloc and visibleColours for visible slices
  var visibleAlloc = instance.ciambellaChartData.datasets[0].alloc.filter(
    (_, i) => instance.sliceIsVisible[i]
  );
  var visibleColours =
    instance.ciambellaChartData.datasets[0].backgroundColor.filter(
      (_, i) => instance.sliceIsVisible[i]
    );

  // Recalculate totalAlloc for visible slices
  var totalAlloc = visibleAlloc.reduce((acc, val) => acc + val, 0);

  /*   
  console.log("ccjsv6_2: VisibleAlloc =", visibleAlloc);
  console.log("ccjsv6_2: visibleColours =", visibleColours);
  console.log("ccjsv6_2: totalAlloc =", totalAlloc);
  */

  // Add event listener to track mouse movements
  instance.canvas.addEventListener("mousemove", function (event) {
    if (instance.ciambellaChartData.datasets[0].alloc.length === 0) {
      return;
    }
    // Get mouse coordinates relative to the canvas
    var mouseX =
      ((event.clientX - instance.canvas.getBoundingClientRect().left) *
        instance.canvas.width) /
      instance.canvas.clientWidth;
    var mouseY =
      ((event.clientY - instance.canvas.getBoundingClientRect().top) *
        instance.canvas.height) /
      instance.canvas.clientHeight;

    sliceStartAngle = 0; // Initialize sliceStartAngle
    instance.hoveredSlice = -1; // Set hoveredSlice to -1 initially
    for (let i = 0; i < visibleAlloc.length; i++) {
      // Check if mouse is over slice
      var sliceAngle = (visibleAlloc[i] / totalAlloc) * 360;
      var sliceEndAngle = sliceStartAngle - sliceAngle; // * 2 * Math.PI;
      // Check if mouse coordinates are within the bounds of the current slice
      //--- should centerX, centerY and radius be prefixed with: instance. ???
      var hoveredSliceIndex = isMouseOverSlice(
        mouseX,
        mouseY,
        instance.centerX,
        instance.centerY,
        instance.radius,
        sliceStartAngle,
        sliceEndAngle,
        i
      );
      if (hoveredSliceIndex !== -1) {
        //console.log("Mouse over slice:", hoveredSliceIndex);
        instance.hoveredSlice = hoveredSliceIndex;
      }

      sliceStartAngle = sliceEndAngle; // Update sliceStartAngle in the anticlockwise direction
    }

    DrawTheChart(instance);
    if (instance.showSliceNumbers) {
      drawSliceNumbers(instance);
    }
    if (instance.showSpiLabels) {
      drawSpiValuesAndClassIdInLabelsWithSwatch(instance);
    }
    drawInnerText(instance);
    drawSpiDotsAndLines(instance);
  });

  function DrawTheChart(instance) {
    if (instance.ciambellaChartData.datasets[0].alloc.length === 0) {
      return;
    }

    /* 
    console.log(
      "ccjsv6_2: drawDoughnut() -> DrawTheChart -> visibleAlloc =",
      visibleAlloc
    );
    console.log(
      "ccjsv6_2: drawDoughnut() -> DrawTheChart -> visibleColours",
      visibleColours
    );
    console.log(
      "ccjsv6_2: drawDoughnut() -> DrawTheChart -> totalAlloc",
      totalAlloc
    );
    */

    //  and draw the slices by Looping through filtered slices in anticlockwise direction
    var DoughnutStartAngle = 0; // = Math.PI / 2 - Math.PI / 2; // Start from the three o'clock position
    //draw big white rectangle to clear the dounut from the canvas before you draw it, to clear the slice selection border

    //ctx.clearRect(0, 0, canvas.width, canvas.height);
    // Set the fill color
    instance.ctx.fillStyle = instance.chartCanvasBackgroundColor;
    // Fill the entire canvas with the specified color

    instance.ctx.fillRect(0, 0, instance.canvas.width, instance.canvas.height);

    /* 
    console.log(
      "ccjsv6_2: DrawTheChart() -> instance.ctx.fillStyle =",
      instance.ctx.fillStyle
    );
    console.log(
      "ccjsv6_2: DrawTheChart() -> instance.canvas.width =",
      instance.canvas.width
    );
    console.log(
      "ccjsv6_2: DrawTheChart() -> instance.canvas.height =",
      instance.canvas.height
    );
    */
    console.log("visibleAlloc.length: ", visibleAlloc.length);
    for (var i = 0; i < visibleAlloc.length; i++) {
      var sliceAngle = (visibleAlloc[i] / totalAlloc) * 2 * Math.PI;

      /* 
      console.log(
        "ccjsv6_2: DrawTheChart() -> i : sliceAngle =",
        i,
        sliceAngle
      );
      */

      // Save the state to avoid interfering with the slice drawing
      instance.ctx.save();
      /* 
      // Set the properties for the shadow
      instance.ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
      instance.ctx.shadowBlur = 20;
      instance.ctx.shadowOffsetX = 10;
      instance.ctx.shadowOffsetY = 10;
        */
      // Draw the slices in anticlockwise direction
      instance.ctx.beginPath();
      instance.ctx.arc(
        instance.centerX,
        instance.centerY,
        instance.radius,
        DoughnutStartAngle,
        DoughnutStartAngle - sliceAngle,
        true
      );

      /* 
      console.log(
        "ccjsv6_2: DrawTheChart() -> instance.centerX =",
        instance.centerX
      );
      console.log(
        "ccjsv6_2: DrawTheChart() -> instance.centerY =",
        instance.centerY
      );
      console.log(
        "ccjsv6_2: DrawTheChart() -> instance.radius =",
        instance.radius
      );
      console.log(
        "ccjsv6_2: DrawTheChart() -> DoughnutStartAngle =",
        DoughnutStartAngle
      );
      console.log("ccjsv6_2: DrawTheChart() -> sliceAngle =", sliceAngle);
      */

      instance.ctx.lineTo(instance.centerX, instance.centerY);

      //draw white border around slices...
      instance.ctx.closePath(); // Close the path

      // Set the border color to white
      instance.ctx.strokeStyle = "white";

      // Set the border width (optional)
      instance.ctx.lineWidth = 2;

      // Draw the border
      instance.ctx.stroke();

      //console.log('hoveredSlice:', hoveredSlice, 'i:', i);
      // Change the fill style to a darker colour when the slice is being hovered over
      if (i === instance.hoveredSlice) {
        console.log("ccjsv6_2: DrawTheChart() -> i : hoveredSlice", i);
        console.log(
          "ccjsv6_2: DrawTheChart() -> visibleColours[i]",
          visibleColours[i]
        );
        instance.ctx.fillStyle = darkenColor(visibleColours[i]);
      } else {
        instance.ctx.fillStyle = visibleColours[i];

        /* 
        console.log(
          "ccjsv6_2: DrawTheChart() -> i : visibleColours[i]",
          i,
          visibleColours[i]
        ); 
        */
      }
      //ctx.fillStyle = visibleColours[i];
      instance.ctx.fill();

      //put border around slice if it's being hovered over
      // Draw a black border around the hovered slice
      if (i === instance.hoveredSlice) {
        //ctx.strokeStyle = 'black';
        //ctx.lineWidth = 4;
        //ctx.stroke();
        //ctx.lineTo(centerX, centerY); // Connect the end point of the arc to the center of the circle
      }

      // Restore the state to clear the path and any transformations
      instance.ctx.restore();
      DoughnutStartAngle -= sliceAngle; // Update startAngle in the anticlockwise direction
    }

    // Draw a white circle in the center
    instance.ctx.fillStyle = instance.holeColour;
    instance.ctx.beginPath();
    instance.ctx.arc(
      instance.centerX,
      instance.centerY,
      instance.holeRadius,
      0,
      2 * Math.PI
    );
    instance.ctx.fill();

    //console.log("ccjsv6_2: DrawTheChart() was run");
  }

  DrawTheChart(instance);

  function isMouseOverSlice(
    mouseX,
    mouseY,
    centerX,
    centerY,
    radius,
    sliceStartAngle,
    sliceEndAngle,
    sliceNum
  ) {
    // Calculate the distance from the center of the circle to the mouse coordinates
    var distance = Math.sqrt(
      Math.pow(Math.abs(mouseX - centerX), 2) +
        Math.pow(Math.abs(mouseY - centerY), 2)
    );
    // Check if the distance is within the radius
    //console.log('distance:', distance, 'radius:', radius);
    if (distance <= radius && distance >= instance.holeRadius) {
      //console.log('IN RANGE', "slicenum:", sliceNum);
      // Calculate the angle between the slice center and the mouse position
      var angle = Math.atan2(mouseY - centerY, mouseX - centerX); //*180/Math.PI;

      // Normalize angle to be between 0 and 2 * Math.PI, subtract it from 360 (then muliply by 180/PI)
      angle = 360 - (((angle + 2 * Math.PI) % (2 * Math.PI)) * 180) / Math.PI;
      sliceStartAngle = Math.abs(sliceStartAngle);
      sliceEndAngle = Math.abs(sliceEndAngle);
      //console.log('mouseangle:', angle, 'sliceStartAngle:', sliceStartAngle, 'sliceEndAngle:', sliceEndAngle);

      // Check if the angle is within the bounds of the current slice
      if (angle >= sliceStartAngle && angle <= sliceEndAngle) {
        //console.log('Mouse over slice:', sliceNum);
        // You can perform additional actions here based on the hovered slice
        return sliceNum;
      }
    }

    return -1;
  }

  function darkenColor(color) {
    console.log("ccjsv6_2: darkenColor() was run");
    console.log("ccjsv6_2: color = ", color);

    if (!color || typeof color !== "string" || !color.startsWith("#")) {
      console.log("Invalid color value", color);
      return; // Early return if color is not defined or invalid
    }

    // Convert the color to RGB format
    var rgb;
    if (color.substring(0, 1) === "#") {
      // Convert 3-character hex code to 6-character hex code
      if (color.length === 4) {
        color =
          "#" +
          color.substring(1, 2) +
          color.substring(1, 2) +
          color.substring(2, 3) +
          color.substring(2, 3) +
          color.substring(3, 4) +
          color.substring(3, 4);
      }
      // Convert hex code to RGB format
      rgb =
        "rgb(" +
        parseInt(color.substring(1, 3), 16) +
        ", " +
        parseInt(color.substring(3, 5), 16) +
        ", " +
        parseInt(color.substring(5, 7), 16) +
        ")";
    } else if (color.substring(0, 4) === "rgba") {
      // Convert RGBA format to RGB format
      rgb = "rgb(" + color.substring(5, color.lastIndexOf(",")) + ")";
    } else {
      rgb = color;
    }

    // Calculate the new RGB values by subtracting 20 from each value
    var newRgb = rgb
      .substring(4, rgb.length - 1)
      .replace(/ /g, "")
      .split(",")
      .map(function (value) {
        return Math.max(parseInt(value) - 60, 0);
      });

    // Convert the new RGB values back to CSS color format
    var newColor = "rgb(" + newRgb.join(",") + ")";

    return newColor;
  }

  //console.log("ccjsv6_2: drawDoughnut() was run");
}

// Define other functions similarly, passing the instance as a parameter

function drawSliceNumbers(instance) {
  //draw slice numbers AntiClockWise

  var startAngle = Math.PI / 2 - Math.PI / 2; // Start from the three o'clock position

  // Filter visibleAlloc and visibleColours for visible slices
  var visibleAlloc = instance.ciambellaChartData.datasets[0].alloc.filter(
    (_, i) => instance.sliceIsVisible[i]
  );
  var visibleColours =
    instance.ciambellaChartData.datasets[0].backgroundColor.filter(
      (_, i) => instance.sliceIsVisible[i]
    );

  // Recalculate totalAlloc for visible slices
  var totalAlloc = visibleAlloc.reduce((acc, val) => acc + val, 0);

  // Loop through filtered slices in anticlockwise direction
  for (var i = 0; i < visibleAlloc.length; i++) {
    var sliceAngle = (visibleAlloc[i] / totalAlloc) * 2 * Math.PI;

    // Calculate the luminance of the background color
    var backgroundColor = hexToRgb(visibleColours[i]);
    var luminance =
      (0.299 * backgroundColor.r +
        0.587 * backgroundColor.g +
        0.114 * backgroundColor.b) /
      255;

    // Decide the text color based on the luminance
    var textColor = luminance > 0.5 ? "black" : "white";

    // Calculate the position for the text using Math.sin and Math.cos
    var textX =
      instance.centerX +
      (instance.sliceNumberDistanceFromCurcumference / 2) *
        Math.cos(startAngle - sliceAngle / 2);
    var textY =
      instance.centerY +
      (instance.sliceNumberDistanceFromCurcumference / 2) *
        Math.sin(startAngle - sliceAngle / 2);

    // Draw the impact value in the center of each slice
    instance.ctx.fillStyle = textColor;
    instance.ctx.font = instance.spiLabelFontSize; // Set the font size and type
    instance.ctx.textAlign = "center";
    instance.ctx.textBaseline = "middle";
    instance.ctx.fillText(i + 1, textX, textY);

    // Decrement startAngle for the next iteration
    startAngle -= sliceAngle;
  }
}

function drawSpiValuesAndClassIdInLabelsWithSwatch(instance) {
  // Draw SPI values and class ID in labels with swatch
  var startAngle = Math.PI / 2 - Math.PI / 2; // Start from the three o'clock position

  // Recalculate totalAlloc for visible slices
  var visibleAlloc = instance.ciambellaChartData.datasets[0].alloc.filter(
    (_, i) => instance.sliceIsVisible[i]
  );
  var totalAlloc = visibleAlloc.reduce((acc, val) => acc + val, 0);

  // Filter .spi[] for visible slices
  var visibleSpi = instance.ciambellaChartData.datasets[0].spi.filter(
    (_, i) => instance.sliceIsVisible[i]
  );
  // Filter .backgroundColor[] for visible slices
  var visibleColours =
    instance.ciambellaChartData.datasets[0].backgroundColor.filter(
      (_, i) => instance.sliceIsVisible[i]
    );
  // Filter .labels[] for visible slices
  var visibleLabels = instance.ciambellaChartData.labels
    .filter((_, i) => instance.sliceIsVisible[i])
    .map((label) => label.split(" -")[0]);

  var hoveredSliceRect = null; // Initialize the hovered slice rectangle to null

  //var textX = "";
  //var textY = "";

  for (var i = 0; i < visibleAlloc.length; i++) {
    var sliceAngle = (visibleAlloc[i] / totalAlloc) * 2 * Math.PI;

    // Draw the rectangles with impact values
    var rectWidth = instance.spiLabelWidth; // Adjust the width as needed
    var rectHeight = 40; // Increased height to accommodate two lines
    var rectX =
      instance.centerX +
      (instance.radius + instance.spiLabelDistanceFromDoughnut) *
        Math.cos(startAngle - sliceAngle / 2) -
      rectWidth / 2;
    var rectY =
      instance.centerY +
      (instance.radius + instance.spiLabelDistanceFromDoughnut) *
        Math.sin(startAngle - sliceAngle / 2) -
      rectHeight / 2;

    // Calculate luminance for the swatch
    var backgroundColor = hexToRgb(visibleColours[i]);
    var luminance =
      (0.299 * backgroundColor.r +
        0.587 * backgroundColor.g +
        0.114 * backgroundColor.b) /
      255;

    // Decide the text color based on the luminance
    var textColor = luminance > 0.5 ? "black" : "white";

    // Draw the white rectangle with a black border
    instance.ctx.fillStyle = "white";
    instance.ctx.strokeStyle = "black";

    if (instance.hoveredSlice !== i) {
      instance.ctx.fillStyle = "white";
      instance.ctx.strokeStyle = "black";
    } else {
      // Save the rectangle for the hovered slice
      hoveredSliceRect = {
        x: rectX,
        y: rectY,
        width: rectWidth,
        height: rectHeight,
        colour: visibleColours[i],
        spi:
          visibleSpi[i] === null ||
          visibleSpi[i] === "null" ||
          isNaN(visibleSpi[i])
            ? "--"
            : visibleSpi[i].toFixed(
                instance.maxNumberOfDecimalPlacesInSpiLabels
              ),
        label: visibleLabels[i],
      };
    }

    instance.ctx.lineWidth = 1;
    instance.ctx.fillRect(rectX, rectY, rectWidth, rectHeight);
    instance.ctx.strokeRect(rectX, rectY, rectWidth, rectHeight);

    // Calculate the position for the swatch
    var swatchHeight = 20;
    var swatchX = rectX;
    var swatchY = rectY;

    // Draw the swatch
    instance.ctx.fillStyle = visibleColours[i];
    instance.ctx.fillRect(swatchX, swatchY, rectWidth, swatchHeight);

    // Draw the classId inside the swatch, centered
    var classIdX = swatchX + rectWidth / 2;
    var classIdY = swatchY + swatchHeight / 2;
    instance.ctx.fillStyle = textColor; // Use the calculated text color
    instance.ctx.font = "10px Arial";
    instance.ctx.textAlign = "center";
    instance.ctx.textBaseline = "middle";
    instance.ctx.fillText(visibleLabels[i], classIdX, classIdY);

    // Draw the SPI value inside the rectangle, below the swatch
    var spiX = rectX + rectWidth / 2;
    var spiY = swatchY + swatchHeight + (rectHeight - swatchHeight) / 2;

    instance.ctx.fillStyle = "black";
    instance.ctx.font = "10px Arial";
    instance.ctx.textAlign = "center";
    instance.ctx.textBaseline = "middle";
    instance.ctx.fillText(
      visibleSpi[i] === null || visibleSpi[i] === "null" || isNaN(visibleSpi[i])
        ? "--"
        : visibleSpi[i].toFixed(instance.maxNumberOfDecimalPlacesInSpiLabels),
      spiX,
      spiY
    );

    startAngle -= sliceAngle; // Decrement startAngle for anticlockwise direction
  }

  // Draw the label for the hovered slice after all labels have been drawn
  if (hoveredSliceRect !== null) {
    instance.ctx.fillStyle = hoveredSliceRect.colour;
    instance.ctx.strokeStyle = "black";
    instance.ctx.lineWidth = 2;
    instance.ctx.fillRect(
      hoveredSliceRect.x,
      hoveredSliceRect.y,
      hoveredSliceRect.width,
      hoveredSliceRect.height
    );
    instance.ctx.strokeRect(
      hoveredSliceRect.x,
      hoveredSliceRect.y,
      hoveredSliceRect.width,
      hoveredSliceRect.height
    );

    // Calculate luminance for the hovered slice
    var hoveredBackgroundColor = hexToRgb(hoveredSliceRect.colour);
    var hoveredLuminance =
      (0.299 * hoveredBackgroundColor.r +
        0.587 * hoveredBackgroundColor.g +
        0.114 * hoveredBackgroundColor.b) /
      255;

    // Decide the text color based on the luminance
    var hoveredTextColor = hoveredLuminance > 0.5 ? "black" : "white";

    // Draw the classId inside the swatch for hovered slice
    classIdX = hoveredSliceRect.x + hoveredSliceRect.width / 2;
    classIdY = hoveredSliceRect.y + swatchHeight / 2;
    instance.ctx.fillStyle = hoveredTextColor; // Use the calculated text color for hover
    instance.ctx.font = "10px Arial";
    instance.ctx.textAlign = "center";
    instance.ctx.textBaseline = "middle";
    instance.ctx.fillText(hoveredSliceRect.label, classIdX, classIdY);

    // Draw the SPI value inside the rectangle for hovered slice
    spiX = hoveredSliceRect.x + hoveredSliceRect.width / 2;
    spiY =
      hoveredSliceRect.y +
      swatchHeight +
      (hoveredSliceRect.height - swatchHeight) / 2;

    instance.ctx.fillStyle = hoveredTextColor; // Use the calculated text color for hover
    instance.ctx.font = "10px Arial";
    instance.ctx.textAlign = "center";
    instance.ctx.textBaseline = "middle";
    instance.ctx.fillText(hoveredSliceRect.spi, spiX, spiY);
  }
}

function drawSpiDotsAndLines(instance) {
  //---------------- Draw datapoints and connecting lines ---------------
  // Draw impact values in labels with swatch AntiClockWise
  var startAngle = Math.PI / 2 - Math.PI / 2; // Start from the three o'clock position

  // Recalculate totalAlloc for visible slices
  var visibleAlloc = instance.ciambellaChartData.datasets[0].alloc.filter(
    (_, i) => instance.sliceIsVisible[i]
  );
  var totalAlloc = visibleAlloc.reduce((acc, val) => acc + val, 0);

  // Filter .spi[] for visible slices
  var visibleSpi = instance.ciambellaChartData.datasets[0].spi.filter(
    (_, i) => instance.sliceIsVisible[i]
  );

  // Initialize px and py arrays
  var px = [];
  var py = [];

  // Draw lines with XOR...
  if (instance.XORLineDrawingMode === true) {
    instance.ctx.globalCompositeOperation = "xor";
  }

  instance.ctx.lineWidth = instance.lineWidth;
  instance.ctx.strokeStyle = instance.linesColour;
  instance.ctx.lineJoin = "round";
  instance.ctx.beginPath();

  // Start at the first point (i=0)...
  for (let i = 0; i < visibleAlloc.length; i++) {
    var spi = visibleSpi[i];

    // Correct for out of range above values...
    if (spi >= 1.4) {
      spi = 1.4;
    }

    // Correct for out of range below values...
    if (spi <= -0.3) {
      spi = -0.3;
    }

    // Calculate dot position based on slice angle and SPI score
    //var angle = (instance.ciambellaChartData.datasets[0].alloc[i] / totalAlloc) * 2 * Math.PI;
    var sliceAngle = (visibleAlloc[i] / totalAlloc) * 2 * Math.PI;

    var currentRadius =
      instance.holeRadius + spi * (instance.radius - instance.holeRadius);

    // Calculate position based on the updated radius
    var dotX =
      instance.centerX + currentRadius * Math.cos(startAngle - sliceAngle / 2);
    var dotY =
      instance.centerY + currentRadius * Math.sin(startAngle - sliceAngle / 2);

    // Store the positions in the arrays
    px[i] = dotX;
    py[i] = dotY;

    if (i === 0) {
      // Move pen to the first point location...
      instance.ctx.moveTo(dotX, dotY);
    } else {
      instance.ctx.lineTo(dotX, dotY);
    }
    startAngle -= sliceAngle; // Decrement startAngle for anticlockwise direction
  }

  instance.ctx.stroke();

  //---------------- Join last datapoint to the first ----------------
  // Move pen to the first point location...
  instance.ctx.beginPath();
  instance.ctx.moveTo(px[0], py[0]); // This should be the index of the first visible dot
  instance.ctx.strokeStyle = instance.linesColour;
  instance.ctx.lineTo(px[visibleAlloc.length - 1], py[visibleAlloc.length - 1]); // This should be the index of the last visible dot
  instance.ctx.stroke();

  // Reset the drawing mode to default...
  instance.ctx.globalCompositeOperation = "source-over";

  // Stop the radius from going negative when the chart is shrunk, when the screen is too narrow...
  if (instance.DataDotRadius < 0) {
    instance.DataDotRadius = 0;
  }

  //---------------- Draw Datapoints ----------------
  instance.ctx.lineWidth = 1;

  for (let i = 0; i < visibleAlloc.length; i++) {
    instance.ctx.beginPath();
    instance.ctx.moveTo(px[i] + instance.DataDotRadius, py[i]);
    instance.ctx.strokeStyle = "black";
    instance.ctx.arc(
      px[i],
      py[i],
      instance.DataDotRadius,
      0,
      Math.PI * 2,
      true
    );
    instance.ctx.stroke();
    instance.ctx.fillStyle = instance.dotFillColour;
    instance.ctx.fill();
  }

  if (instance.useColoursForStartAndEndDots === true) {
    //---------------- Make first datapoint green ----------------
    instance.ctx.beginPath();
    instance.ctx.moveTo(px[0] + instance.DataDotRadius, py[0]);
    instance.ctx.arc(
      px[0],
      py[0],
      instance.DataDotRadius,
      0,
      Math.PI * 2,
      true
    );
    instance.ctx.fillStyle = instance.firstDotFillColour;
    instance.ctx.fill();

    //---------------- Make last datapoint red ----------------
    instance.ctx.beginPath();
    instance.ctx.moveTo(
      px[visibleAlloc.length - 1] + instance.DataDotRadius,
      py[visibleAlloc.length - 1]
    );
    instance.ctx.arc(
      px[visibleAlloc.length - 1],
      py[visibleAlloc.length - 1],
      instance.DataDotRadius,
      0,
      Math.PI * 2,
      true
    );
    instance.ctx.fillStyle = instance.lastDotFillColour;
    instance.ctx.stroke();
    instance.ctx.fill();
  }
  /* 
  var SPITotal = 0;

  // Calculate average SPI for all slices selected...
  for (var a = 0; a < visibleAlloc.length; a += 1) {
    spi = visibleSpi[a];

    if (spi === null || isNaN(spi)) {
      spi = 0;
    }

    SPITotal += spi;
  }

  SPI = SPITotal / visibleAlloc.length;
   */
  //console.log("SPI: " + SPI);
  //console.log("numOfSelectedSlices: " + visibleAlloc.length);
}

function drawInnerText(instance) {
  //---------------- Draw SPI figure in center of the chart ----------------

  /* 
  console.log(
    "SPI Score raw ",
    instance.ciambellaChartData.datasets[0].spiScore
  ); 
  */

  //don't draw the inner text if SPi is Not A Number...
  if (isNaN(instance.ciambellaChartData.datasets[0].spiScore)) {
    //return;
  }

  if (instance.ciambellaChartData.datasets[0].alloc.length === 0) {
    return;
  }

  //average of visible spi values...
  //var spiScore=parseFloat(SPI).toFixed(3);

  instance.ctx.font =
    "normal " + instance.spiScoreFontSize + "px " + instance.spiScoreFontFamily;
  instance.ctx.fillStyle = "#25BDE2";
  instance.ctx.strokeStyle = "#109DC2";
  instance.ctx.strokeWidth = "0px";
  instance.ctx.textAlign = "center";
  instance.ctx.textBaseline = "middle";

  //only draw the spiScore if it's 'allocation' field is present, i.e. agg===false, set in adapter,
  //or not -1 (it is set to -1 when a search is performed, it will be -1 while we are waiting for rank_score API to return the SPI score)...
  // I tried adding:  && !isNaN(ciambellaChartData.datasets[0].spiScore) to fix the dashboard resize bug, but it will just cause the SPI number to no be drawn. Not a fix.
  //if (agg === false && ciambellaChartData.datasets[0].spiScore !== -1) {
  if (
    instance.agg === false ||
    instance.ciambellaChartData.datasets[0].spiScore !== null
  ) {
    //console.log("CCSPI: ciambellaChartData.datasets[0].spiScore before parsing:", ciambellaChartData.datasets[0].spiScore);

    // Check if spiScore is undefined
    /* if (ciambellaChartData.datasets[0].spiScore === undefined) {
     console.warn("CCSPI: spiScore is undefined, not drawing on the canvas.");
     return;
   } */

    //use the spiScore from the data...
    var spiScore = parseFloat(
      instance.ciambellaChartData.datasets[0].spiScore
    ).toFixed(3);

    //console.log("CCSPI: spiScore after parsing:", spiScore);

    ///draw the score
    //instance.ctx.fillText(spiScore, centerX, centerY);

    // Check if spiScore is a valid number
    if (!isNaN(spiScore)) {
      instance.ctx.fillText(spiScore, instance.centerX, instance.centerY);
      // No outline: instance.ctx.strokeText(spiScore, instance.centerX, instance.centerY);
    }

    /* else {
     console.warn("CCSPI: spiScore is NaN, not drawing on the canvas.");
   } */

    //no outline: instance.ctx.strokeText(spiScore, centerX, centerY);
  }
  //draw label...
  instance.ctx.font =
    "normal " +
    instance.spiInnerLabelFontSize +
    "px " +
    instance.spiInnerLabelFontFamily;
  instance.ctx.fillStyle = instance.spiInnerLabel;

  instance.ctx.fillText(
    "Sustainability",
    instance.centerX,
    instance.centerY - 36 - instance.spiInnerLabelDistanceFromCenter
  );
  instance.ctx.fillText(
    "Performance",
    instance.centerX,
    instance.centerY - 27 - instance.spiInnerLabelDistanceFromCenter
  );
  instance.ctx.fillText(
    "Index",
    instance.centerX,
    instance.centerY - 18 - instance.spiInnerLabelDistanceFromCenter
  );

  //draw url...
  instance.ctx.font =
    "normal " + instance.URLLabelFontSize + "px " + instance.URLLabelFontFamily;
  instance.ctx.fillStyle = instance.spiInnerLabel;

  instance.ctx.fillText(
    "strive2thrive.earth",
    instance.centerX,
    instance.centerY + 21 + instance.spiInnerLabelDistanceFromCenter
  );
}

// Hex to RGB conversion function
function hexToRgb(hex) {
  // Check if hex is defined and is a string
  if (typeof hex !== "string") {
    console.error("CC: Invalid input: hex value is not a string");
    return { r: 0, g: 0, b: 0 }; //null; // or you can return a default value like { r: 0, g: 0, b: 0 }
  }
  // Remove the hash if it's present
  hex = hex.replace(/^#/, "");

  // Handle 3-character hex colors
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map(function (c) {
        return c + c;
      })
      .join("");
  }

  var bigint = parseInt(hex, 16);
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
  };
}

function rgbToHex(rgb) {
  var r = parseInt(rgb.substring(4, rgb.indexOf(",")));
  var g = parseInt(rgb.substring(rgb.indexOf(",") + 1, rgb.lastIndexOf(",")));
  var b = parseInt(rgb.substring(rgb.lastIndexOf(",") + 1, rgb.indexOf(")")));

  var hexR = r.toString(16).padStart(2, "0");
  var hexG = g.toString(16).padStart(2, "0");
  var hexB = b.toString(16).padStart(2, "0");

  return "#" + hexR + hexG + hexB;
}

//this function was rewritten by copilot based on mine.
//It is more compact and produces a better gradient.
function rainbowColours(total) {
  var pool = [];
  var maxPrimary = 235;
  var numberOfSpectralColours = 6;
  var stepAmount = (maxPrimary / (total - 1)) * numberOfSpectralColours;

  for (let i = 0; i < total; i++) {
    var r, g, b;
    if (i === 0) {
      // Set the RGB values directly for a pink color
      r = 255; // Maximum red
      g = 225; // Medium green
      b = 240; // High blue
    } else if (i < total / numberOfSpectralColours) {
      // Transition smoothly from pink to red by gradually reducing blue
      r = 255; // Keep red at maximum
      g = 225 - Math.floor((225 / (total / numberOfSpectralColours)) * i);
      b = Math.max(
        240 - Math.floor((240 / (total / numberOfSpectralColours)) * i),
        0
      );
    } else if (i < (total / numberOfSpectralColours) * 2) {
      r = maxPrimary;
      g = stepAmount * (i - total / numberOfSpectralColours);
      b = 0;
    } else if (i < (total / numberOfSpectralColours) * 3) {
      r = Math.max(
        maxPrimary - stepAmount * (i - (total / numberOfSpectralColours) * 2),
        0
      );
      g = maxPrimary;
      b = Math.max(
        255 -
          maxPrimary +
          stepAmount * (i - (total / numberOfSpectralColours) * 2),
        0
      );
    } else if (i < (total / numberOfSpectralColours) * 4) {
      r = 0;
      g = Math.max(
        maxPrimary - stepAmount * (i - (total / numberOfSpectralColours) * 3),
        0
      );
      b = maxPrimary;
    } else if (i < (total / numberOfSpectralColours) * 5) {
      r = Math.max(stepAmount * (i - (total / numberOfSpectralColours) * 4), 0);
      g = 0;
      b = maxPrimary;
    } else {
      r = maxPrimary;
      g = Math.max(stepAmount * (i - (total / numberOfSpectralColours) * 5), 0);
      b = maxPrimary;
    }

    var hexColor = rgbToHex("rgb(" + r + "," + g + "," + b + ")");
    pool.push(hexColor);
  }

  return pool;
}
