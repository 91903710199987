import React, { useState, useEffect } from "react";
//import { loadCiambellaChartData } from "./ccjsv6_2";
import { useTheme } from "@mui/styles";
import Box from "@mui/material/Box";
import DataTable from "react-data-table-component";

function THRIVEGrid({
  APIData,
  sliceIsVisible,
  setSliceIsVisible,
  weight,
  setWeight,
  selectAllSlices,
  setSelectAllSlices,
}) {
  //console.log("GRIDDATA: on render columnSort:", columnSort);

  const MAXIMUM_NUMBER_OF_SLICES = 5000;

  const theme = useTheme();

  //init gridAPIData state variable to hold the data from the API formatted for DataTables component...
  const [gridAPIData, setGridAPIData] = useState([]);
  const [columnSort, setColumnSort] = useState({ name: "", direction: "" });

  useEffect(() => {
    console.log("GRIDDATA: APIData is set: ", APIData);

    let parsedData;
    try {
      parsedData = JSON.parse(APIData);
    } catch (error) {
      console.error("Failed to parse APIData:", error);
      return;
    }

    // Check if parsedData and parsedData.data exist and are in the correct format
    if (parsedData && Array.isArray(parsedData.data)) {
      // Convert parsedData to the format required by DataTable
      const formattedData = parsedData.data.map((item, index) => ({
        ...item,
        record_pos: item.record_pos || index + 1, // Add record_pos if it doesn't exist
        topic_name: `${item.class_path_id.replace(/\|/g, "")} ${
          item.topic_name
        }`,
        allocation:
          "allocation" in item
            ? item.allocation === null || item.allocation === "null"
              ? "--"
              : parseFloat(item.allocation)
            : "--",
        inner_limit:
          "inner_limit" in item
            ? item.inner_limit === null || item.inner_limit === "null"
              ? "--"
              : parseFloat(item.inner_limit)
            : "--",
        outer_limit:
          "outer_limit" in item
            ? item.outer_limit === null || item.outer_limit === "null"
              ? "--"
              : parseFloat(item.outer_limit)
            : "--",
        impact:
          "impact" in item
            ? item.impact === null || item.impact === "null"
              ? "--"
              : parseFloat(item.impact).toFixed(3)
            : "--",
        score:
          item.score === null || item.score === "null"
            ? "--"
            : parseFloat(item.score * weight[index]).toFixed(3),
        weight: parseFloat(weight[index]),
      }));
      //console.log("GRIDDATA: Formatted Data:", formattedData);
      setGridAPIData(formattedData);
      console.log("GRIDDATA: sliceIsVisible:", sliceIsVisible);
      console.log("GRIDDATA: weight:", weight);
    } else {
      //console.log("GRIDDATA: parsedData is empty or undefined", parsedData);
    }
  }, [APIData, weight]); // Dependency array ensures this runs when APIData changes

  //var legendData = loadCiambellaChartData(APIData, sliceIsVisible, weight);

  /* Not used in this version of the code...
  // use {} for 'instance', null for SPIScore, and {} searchFormData, not needed to get legendData from APIData...
  var legendData = loadCiambellaChartData(
    {},
    APIData,
    sliceIsVisible,
    weight,
    null,
    {}
  );

  //check if legendData is undefined and return null if it is
  if (!legendData) {
    return null;
  }
 */

  /* 
  //set sliceIsVisible state variable to a new array where the element at index is toggled...
  function toggleGridItem(index) {
    setSliceIsVisible(toggleSlice(sliceIsVisible, index));
  }

  //return a new array where the element at index is toggled...
  function toggleSlice(sliceIsVisible, index) {
    return sliceIsVisible.map((value, i) => {
      if (i === index) {
        return !value;
      } else {
        return value;
      }
    });
  }
 */

  const handleSort = (column, sortDirection) => {
    setColumnSort((prevState) => {
      const newSort = { name: column.selector, direction: sortDirection };
      console.log("GRIDDATA: columnSort:", newSort);
      return newSort;
    });
  };

  const toggleGridItem = (index) => {
    const newSliceIsVisible = [...sliceIsVisible];
    newSliceIsVisible[index] = !newSliceIsVisible[index];
    setSliceIsVisible(newSliceIsVisible);
  };

  const handleWeightChange = (index, value) => {
    const newWeight = [...weight];
    newWeight[index] = value;
    setWeight(newWeight);
  };

  const columns = [
    {
      name: (
        <input
          type="checkbox"
          checked={selectAllSlices === true}
          onChange={() => {
            toggleShowHideAll();
          }}
        />
      ),
      cell: (row) => (
        <input
          type="checkbox"
          checked={sliceIsVisible[row.record_pos - 1]}
          onChange={() => toggleGridItem(row.record_pos - 1)}
        />
      ),
      width: "30px", // Set a fixed width for the Visible column
    },
    {
      name: "Weight",
      cell: (row) => (
        <input
          type="text"
          value={weight[row.record_pos - 1]}
          onChange={(event) => {
            const newValue = event.target.value;
            if (/^-?\d*\.?\d*$/.test(newValue)) {
              handleWeightChange(row.record_pos - 1, newValue);
            }
          }}
          onBlur={(event) => {
            if (event.target.value === "-") {
              handleWeightChange(row.record_pos - 1, "1");
            }
          }}
          style={{ width: "40px" }}
        />
      ),
      sortable: true,
      width: "60px",
    },
    {
      name: "Topic Name",
      selector: (row) => row.topic_name,
      sortable: true,
      cell: (row) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {row.topic_name}
        </div>
      ),
      width: "30%",
    },
    {
      name: "Allocation",
      selector: (row) => row.allocation,
      sortable: true,
      minWidth: "10px",
    },
    {
      name: "Inner",
      selector: (row) => row.inner_limit,
      sortable: true,
      minWidth: "10px",
    },
    {
      name: "Outer",
      selector: (row) => row.outer_limit,
      sortable: true,
      minWidth: "10px",
    },
    {
      name: "Impact",
      selector: (row) => row.impact,
      sortable: true,
      minWidth: "10px",
    },
    {
      name: "Score",
      selector: (row) => row.score,
      sortable: true,
      minWidth: "10px",
    },
    // Add more columns as needed
  ];

  //this function toggles the visibility of all rows/slices...
  //set sliceIsVisible state variable to a new array where all elements are toggled...
  function toggleAllSlices() {
    setSliceIsVisible(toggleAll(sliceIsVisible));
    //toggle setSelectAllSlices state variable...
    setSelectAllSlices(!selectAllSlices);
  }

  //return a new array where all elements are toggled...
  function toggleAll(sliceIsVisible) {
    return sliceIsVisible.map((value, i) => {
      return !value;
    });
  }

  //this function SHOWS ALL rows/slices or HIDES ALL rows/slices...
  function toggleShowHideAll() {
    if (selectAllSlices === true) {
      setSliceIsVisible(Array(MAXIMUM_NUMBER_OF_SLICES).fill(false));
      //toggle setSelectAllSlices state variable...
      setSelectAllSlices(!selectAllSlices);
    } else {
      setSliceIsVisible(Array(MAXIMUM_NUMBER_OF_SLICES).fill(true));
      //toggle setSelectAllSlices state variable...
      setSelectAllSlices(!selectAllSlices);
    }
  }

  const whiteRow = {
    border: "1px solid black",
    fontSize: "14px",
    backgroundColor: "#fff",
  };

  const greyRow = {
    border: "1px solid black",
    fontSize: "14px",
    backgroundColor: "#e0e0e0",
  };

  const conditionalRowStyles = [
    {
      when: (row) => !sliceIsVisible[row.record_pos - 1],
      style: {
        backgroundColor: "#e0e0e0", // Dark grey for selected rows
        "&:hover": {
          backgroundColor: "#f5f5f5", // Light grey for hover effect
        },
      },
    },
    {
      when: (row) => sliceIsVisible[row.record_pos - 1], // When checkbox is ticked
      style: {
        backgroundColor: "#fff", // White for selected rows
        "&:hover": {
          backgroundColor: "#f5f5f5", // Light grey for hover effect
        },
      },
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: theme.palette.skyBlue.main,
        color: "#fff",
        "&:hover": {
          color: "#fff",
        },
        padding: "4px 2px 4px 2px", // Adjust padding to reduce space between columns
      },
    },
    cells: {
      style: {
        whiteSpace: "normal", // Allow text to wrap
        wordWrap: "break-word", // Break long words
        padding: "4px 2px 4px 2px", // Adjust padding to reduce space between columns
      },
    },
  };

  //log datatable props to console...
  //console.log("GRIDDATA: DataTable Data:", gridAPIData);
  //console.log("GRIDDATA: DataTable Columns:", columns);
  //console.log("GRIDDATA: DataTable Sort:", columnSort);
  /* <div>Nothing to see here...</div> */

  /*   <div>Nothing</div>  
 
     key={columnSort.name === '' ? 'default' : columnSort.name + columnSort.direction} // Use key prop to force re-render only when columnSort is reset

 */

  return (
    <DataTable
      columns={columns}
      data={gridAPIData}
      onSort={handleSort}
      conditionalRowStyles={conditionalRowStyles}
      customStyles={customStyles}
      {...(columnSort.name && { defaultSortField: columnSort.name })}
      {...(columnSort.direction && {
        defaultSortAsc: columnSort.direction === "asc",
      })}
    />
  );
}

export default THRIVEGrid;
