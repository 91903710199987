import React, { useRef, useEffect } from "react";
import {
  loadCiambellaChartData,
  initChart,
  paint,
  clearChart,
  createChartInstance,
} from "./ccjsv6_3"; //new version with slicePadding, showoDots,

// A custom hook that returns the context of a canvas element
const useCanvas = (canvasRef) => {
  const [context, setContext] = React.useState(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    setContext(ctx);
  }, [canvasRef]);

  return context;
};

// A React component that renders a canvas and draws something on it using JavaScript
const CiambellaChart_2 = ({
  setSnippetDataObject,
  APIData,
  sliceIsVisible,
  weight,
  selectAllSlices,
  SPIScore,
  searchFormData,
}) => {
  const canvasRef = useRef(null);
  const ctx = useCanvas(canvasRef);

  // Create a chart instance
  const chartInstance = useRef(createChartInstance()).current;

  useEffect(() => {
    if (ctx) {
      let data;
      if (APIData) {
        data = loadCiambellaChartData(
          chartInstance,
          APIData,
          sliceIsVisible,
          weight,
          SPIScore,
          searchFormData
        );
        initChart(chartInstance, ctx, canvasRef);
        paint(chartInstance);
      } else {
        data = loadCiambellaChartData(
          chartInstance,
          APIData,
          sliceIsVisible,
          weight,
          SPIScore,
          searchFormData
        );
        initChart(chartInstance, ctx, canvasRef);
        clearChart(chartInstance);
      }
      setSnippetDataObject(data);
    }
  }, [
    ctx,
    APIData,
    sliceIsVisible,
    weight,
    selectAllSlices,
    SPIScore,
    setSnippetDataObject,
  ]);

  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
          border: "0px solid #ddd",
          width: "100%",
          height: "100%",
          padding: "0px",
        }}
      >
        <canvas
          id="CiambellaChart"
          ref={canvasRef}
          width={500}
          height={500}
          style={{
            backgroundColor: "white",
            border: "0px solid #aaa",
            width: "100%",
            height: "100%",
            padding: "0px",
            boxSizing: "border-box",
          }}
        />
      </div>
    </div>
  );
};

export default CiambellaChart_2;
